import { useDispatch } from 'react-redux';
import reduxConstants from '../redux/reduxConstants';
import { create } from '../redux/stateSlice';
import store from '../redux/store';

export default function useUpdateSchema() {
  const dispatch = useDispatch();

  return (path, key, newObj, _id, UUID) => {
    const schema =
      store.getState()[reduxConstants.STORE_NAME][reduxConstants.config.ANALYTICS_SCHEMA];

    if (path == null) {
      dispatch(
        create({
          setPath: `${reduxConstants.config.ANALYTICS_SCHEMA}`,
          value: { ...schema, [key]: newObj },
        })
      );
      return null;
    }
    // if (!get(schema, path, null)) {
    //   if (path == null) {

    //   }
    //   // dispatch(
    //   //   create({
    //   //     setPath: `${reduxConstants.config.ANALYTICS_SCHEMA}.${path}`,
    //   //     value: { [_id]: UUID, [key]: newObj },
    //   //   })
    //   // );
    //   // return UUID;
    // }
    dispatch(
      create({
        setPath: `${reduxConstants.config.ANALYTICS_SCHEMA}.${path}.${key}`,
        value: newObj,
      })
    );
    return null;
  };
}
