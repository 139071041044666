import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BoardState, Column as ColumnType } from './kanbanTypes';
import ColumnData from './Column';
import styles from './kanban.module.scss';

interface boardProps {
  boardData: BoardState;
  setBoardData: Function;
  updateData: Function;
  component: any;
  handleComponentsUpdate: any;
}
const Board = ({
  boardData,
  setBoardData,
  component,
  updateData,
  handleComponentsUpdate,
}: boardProps) => {
  const handleUpdateColumnData = ({ boardOrder }: { boardOrder: any[] }) => {
    handleComponentsUpdate({
      componentSchema: {
        ...component,
        kanbanSetting: { ...(component?.kanbanSetting ?? {}), boardOrder: boardOrder },
      },
    });
  };
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(boardData.columnOrder);
      const [selectedColumn] = newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, selectedColumn);
      handleUpdateColumnData({ boardOrder: newColumnOrder });
      setBoardData((prev: BoardState) => ({
        ...prev,
        columnOrder: newColumnOrder,
      }));
      return;
    }

    const start = boardData.columns[source.droppableId];
    const finish = boardData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      setBoardData((prev: BoardState) => ({
        ...prev,
        columns: {
          ...prev.columns,
          [newColumn.id]: newColumn,
        },
      }));

      return;
    }
    const kanbanSetting = component?.kanbanSetting ?? {};
    const boardField = kanbanSetting?.boardField ?? '';

    const updatedData = {
      ...(boardData?.tasks?.[draggableId] ?? {}),
      [boardField]: finish?.id,
    };
    updateData({
      id: updatedData.id,
      updatedData: updatedData,
    });

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    setBoardData((prev: BoardState) => ({
      ...prev,
      columns: {
        ...prev.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='all-columns' direction='horizontal' type='column'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={styles.kanbanCardOuterContainer}
          >
            {boardData.columnOrder.map((boardElem, index) => {
              const boardLabel = boardElem?.label ?? '';
              const boardValue = boardElem?.value ?? '';
              const column: ColumnType = boardData.columns?.[boardValue] ?? {
                id: '',
                title: '',
                taskIds: [],
              };
              const tasks = (column?.taskIds ?? []).map((taskId: any) => boardData.tasks[taskId]);

              return (
                <Draggable draggableId={column?.id} index={index} key={column?.id}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                      className={styles.kanbanCardContainer}
                    >
                      <ColumnData
                        key={boardValue}
                        boardLabel={boardLabel}
                        boardValue={boardValue}
                        column={column}
                        tasks={tasks}
                        dragHandleProps={provided.dragHandleProps}
                        component={component}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Board;
