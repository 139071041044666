import _ from 'lodash';

const sortArrayOfObjects = ({
  arrayOfObjects,
  sortBy = 'updatedAt',
  isSortAscending = true,
  allSortingObject,
  nameKey = 'name',
  secondaryNameKey = 'name',
}) => {
  const sorted = _.sortBy(arrayOfObjects, (obj) => {
    if (sortBy === allSortingObject.alphabetically.key) {
      return (obj?.[nameKey] ?? obj?.[secondaryNameKey])?.toLowerCase();
    }
    if (sortBy === allSortingObject.createdAt.key) {
      return -obj.createdAt; // Using the negative value since larger value (later timestamp) should come first
    }
    if (sortBy === allSortingObject.updatedAt.key) {
      return -obj.updatedAt; // Using the negative value since larger value (later timestamp) should come first
    }
    if (sortBy === allSortingObject.connectionName.key) {
      return obj.connectionName?.toLowerCase();
    }
  });
  return isSortAscending ? sorted : sorted.reverse();
};

export default sortArrayOfObjects;
