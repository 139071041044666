import React, { useState } from 'react';
import styles from './PDFTemplate.module.scss';
import Editor from '@monaco-editor/react';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
import HtmlToPdf from './HtmlToPdf';
import { defaultHtmlContent } from './defaultHtmlContentData';
import { mls } from 'lib/multilanguagesupport';
import CodeContainer from './CodeContainer';
import { Button, Form } from 'react-bootstrap';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AIToQuery from '../../QueryGenerator/AIToQuery';
import { FaHistory } from 'react-icons/fa';

interface emailTemplateProps {
  downloadPdfButtonRef: any;
  rowData: any;
  connectedTablesData: any;
  appID: string;
  templateData: any;
  setTemplateData: Function;
}
const codeContainerTypes = {
  code: {
    key: 'code',
    name: 'Code',
  },
  data: {
    key: 'data',
    name: 'Data',
  },
  variables: {
    key: 'variables',
    name: 'Variables',
  },
};
const EmailTemplate = ({
  downloadPdfButtonRef,
  rowData,
  appID,
  connectedTablesData,
  templateData,
  setTemplateData,
}: emailTemplateProps) => {
  const [htmlContent, setHtmlContent] = useState(templateData?.htmlString);
  // const [sampleData, setSampleData] = useState(htmlContentData);
  const [sampleData, setSampleData] = useState(rowData);
  const [variableData, setVariableData] = useState('');

  const [codeContainerType, setCodeContainerType] = useState(codeContainerTypes.code.key);
  const debounceHtmlContent = useDebounce(htmlContent);
  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);
  const [isAIQuaryPopup, setIsAIQuaryPopup] = useState(false);
  const [aiQuaryData, setAiQuaryData] = useState('');
  const handelContainetChange = (value: string) => {
    setHtmlContent(value ?? '');
    setTemplateData({ ...templateData, htmlString: value ?? '' });
  };
  const [query, setQuery] = useState('');
  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };
  const handleGenerateCode = async () => {
    let tableName: string[] = [];
    let columnSchema: { [key: string]: any }[] = [];
    Object.keys(connectedTablesData).forEach((connectedTablesKey) => {
      tableName = [...tableName, connectedTablesKey];
      const connectedTablesElem = connectedTablesData[connectedTablesKey];
      const newColumSchema = (connectedTablesElem?.columnSchema ?? []).map((columnElem: any) => {
        return { ...columnElem, tableID: connectedTablesKey };
      });
      columnSchema = [...columnSchema, ...newColumSchema];
    });
    const connectedTableFinalData = {
      tableName: tableName,
      columnSchema: columnSchema,
    };
    const baseUrl = `aiQuery/${appID}`;
    const data = {
      queryArray: [
        {
          promptType: 'PDFTemplate',
          query: query,
          apiType: 'PUT',
        },
      ],

      data: {
        tableName: connectedTableFinalData?.tableName,
        columnSchema: connectedTableFinalData?.columnSchema,
      },
    };

    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes?.[0]) {
      setIsAIQuaryPopup(true);
      setAiQuaryData(componentRes[0]?.response ?? '');
      setIsAiQueryLoading(false);
    } else {
      setIsAiQueryLoading(false);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleGenerateCode();
  };
  const handleCodeChange = (data: string) => {
    setHtmlContent(data);
  };
  return (
    <div className={styles.PDFTemplateOuterContainer}>
      <div className={styles.PDFTemplateContainer}>
        <div className={styles.templateLeftContainer}>
          <div className={styles.codeContainerHeader}>
            {React.Children.toArray(
              Object.keys(codeContainerTypes).map((codeContainerTypeKey) => {
                const codeContainerTypeElem =
                  codeContainerTypes[codeContainerTypeKey as keyof typeof codeContainerTypes];
                return (
                  <div
                    className={`${styles.codeContainerHeaderOption} ${
                      codeContainerType === codeContainerTypeKey ? styles.activeContainerTab : ''
                    }`}
                    onClick={() => setCodeContainerType(codeContainerTypeElem.key)}
                  >
                    {mls(codeContainerTypeElem.name)}
                  </div>
                );
              })
            )}
          </div>
          <CodeContainer
            sampleData={sampleData}
            htmlContent={htmlContent}
            setSampleData={setSampleData}
            handelContainetChange={handelContainetChange}
            codeContainerType={codeContainerType}
            codeContainerTypes={codeContainerTypes}
            variableData={variableData}
            setVariableData={setVariableData}
          />
        </div>
        <div className={styles.templateRightContainer}>
          <HtmlToPdf
            htmlContent={debounceHtmlContent}
            htmlContentData={sampleData}
            downloadPdfButtonRef={downloadPdfButtonRef}
          />
        </div>
      </div>
      <div>
        {isAIQuaryPopup ? (
          <AIToQuery
            isAIQuaryPopup={isAIQuaryPopup}
            aiQuaryData={aiQuaryData}
            setIsAIQuaryPopup={setIsAIQuaryPopup}
            handleCodeChange={handleCodeChange}
            defaultLanguage={'handlebars'}
            code={htmlContent}
          />
        ) : null}
        <Form className={styles.queryGenerator} onSubmit={(e) => handleSubmit(e)}>
          <div className={styles.queryHistoryButton} onClick={() => setIsAIQuaryPopup(true)}>
            <FaHistory />
          </div>
          <Form.Group controlId='queryInput' className={styles.queryInput}>
            <Form.Control
              type='text'
              // placeholder='Type your query here...'
              placeholder={mls('Generate Code using AI ...')}
              value={query}
              onChange={handleQueryChange}
            />
          </Form.Group>
          <Button variant='primary' onClick={handleGenerateCode} className={styles.generateButton}>
            {isAiQueryLoading ? (
              <>
                <CircularProgress color='inherit' size={12} />
                &nbsp;
              </>
            ) : (
              <SendIcon fontSize={'small'} />
            )}
            &nbsp;
            {mls('Send')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default EmailTemplate;
