import React, { useRef, useState } from 'react';
import './multiselectWithApi.scss';
import x from '../../assets/x.svg';
import { IoMdCheckmark } from 'react-icons/io';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import useDebounce from '../../hooks/useDebounce';
// import loadingImage from '../../assets/listIcon.svg';
import loadingImage from '../../assets/loadingImage.gif';
import { getDefaultAppID } from '../../utils/getAppID';
import localStorage from '../../utils/localStorage';
import { baseAPI } from '../../utils/connections';
import { mls } from 'lib/multilanguagesupport';
import ReactSelectDropDown from 'lib/reusable-components/Components/DropDowns/ReactSelectDropDown';
interface props {
  options: any;
  onSelect: any;
  onRemove: any;
  selectedValues: any;
  showCheckbox: any;
  isObject: any;
  show: boolean;
  targetElem: any;
  appID: string;
  sheetID: string;
  startingPage?: number;
  isJoinedTable: string | boolean;
  isDropDownFixed?: boolean;
  inputPlaceholder?: string;
}

const localStorageData = localStorage.retrieveData('config_store');
const newAppID = getDefaultAppID(localStorageData?.login);
const API = baseAPI;
const MultiselectWithApi = (props: props) => {
  const {
    options,
    onSelect,
    onRemove,
    selectedValues,
    // showCheckbox,
    // isObject,
    show = false,
    targetElem,
    appID,
    sheetID,
    isJoinedTable,
    startingPage = 2,
    isDropDownFixed = true,
    inputPlaceholder = mls('Select'),
  } = props;
  const allValues = targetElem?.filterData?.allValues ?? [];

  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);
  const [searchInput, setsearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(startingPage);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const searchInputDebounce = useDebounce(searchInput);
  const textInput = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(true);
  const [apiLock, setApiLock] = useState(false);

  const getItemByApi = async (text: string) => {
    const maxResponse = 25;
    if (apiLock) {
      return;
    }
    const controller = new AbortController();
    setLoading(true);
    const endPoint = `searchColumns`;
    const body = {
      appID: appID,
      sheetID: sheetID,
      tableID: targetElem.tableName,
      columnName: `${targetElem.columnName}`,
      keyword: text,
      isJoinedTable: isJoinedTable,
      page: currentPage,
    };
    try {
      const response = await fetch(`${API}/${appID || newAppID}/${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
        signal: controller.signal,
      });
      const responseData = await response.json();
      if (responseData?.response?.error) {
        throw new Error('response Error');
      }

      const addData = responseData?.response?.data?.value;

      if (addData.length < maxResponse) {
        setApiLock(true);
      } else {
        setCurrentPage((currentPage) => currentPage + 1);
      }
      setFilteredOptions((currentData: []) => [...currentData, ...addData]);
    } catch (error) {
      setLoading(false);
    }
  };
  const scrollReachedToBottom = () => {
    if (!apiLock) {
      setLoading(true);
    }
    const debouncedgetItemByApi = debounce(getItemByApi, 2000);
    debouncedgetItemByApi(searchInput);
  };
  const handleScroll = (e: any) => {
    if (allValues) {
      return;
    }
    const targetHight = Math.floor(e.target.scrollHeight - e.target.scrollTop - 4);
    const targetParentHeight = Math.round(e.target.clientHeight);

    if (targetHight === targetParentHeight) {
      scrollReachedToBottom();
    }
  };
  const handleDiv = () => {
    setisDivOpen(!isDivOpen);
  };
  const handleFocus = () => {
    setisDivOpen(true);
    textInput.current.focus();
  };
  const handleSelect = (target: string, ischecked: boolean) => {
    if (ischecked) {
      const removedElem = selectedValues.filter((elem: string) => elem !== target);
      onRemove(removedElem);
      return;
    }
    onSelect([...selectedValues, target]);
  };

  const handleSearchInput = (e: any) => {
    setCurrentPage(startingPage);
    setApiLock(false);
    setCurrentPage(1);
    setsearchInput(e.target.value);
    // if (e.target.value.trim() === '') {
    //   setFilteredOptions(options);
    //   return;
    // }
  };
  const handleSearch = (target: string) => {
    if (allValues) {
      const filtered = options.filter((elem: string) => {
        return elem.toLowerCase().includes(target.toLowerCase());
      });
      setFilteredOptions(filtered);
      return;
    }
    setFilteredOptions([]);
    getItemByApi(target);
  };
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSelect(searchInput, false);
      setsearchInput('');
    }
    if (e.key === 'Backspace') {
      let str = searchInput ?? '';
      str = str.substring(0, str.length - 1);
      setsearchInput(str);
    }
  };
  useUpdateEffect(() => {
    if (!isDivOpen) {
      if (searchInput) {
        const isSkip: any = handleSelect(searchInput, false);
        if (!isSkip?.skip) {
          setsearchInput('');
        }
      }
      const timer = setTimeout(() => setisContent(isDivOpen), 300);
      return () => {
        clearTimeout(timer);
      };
    }
    setisContent(isDivOpen);
  }, [isDivOpen]);

  useUpdateEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useUpdateEffect(() => {
    handleSearch(searchInput);
  }, [searchInputDebounce]);

  useUpdateEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => handleDiv(), 10);
      }
    }

    if (isDivOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isDivOpen]);

  const dropDownOption = [
    {
      value: 'chocolate',
      label: 'Chocolate',

      isContent,
      filteredOptions,
      selectedValues,
      handleSelect,
      apiLock,
      loading,
      inputPlaceholder,
      //New
      handleEnter,
      textInput,
      searchInput,
      handleFocus,
      handleSearchInput,
      handleScroll,
      //Extra
      dropDownData: { isOpen: isDivOpen },
      CustomOption,
      ValueContainer,
    },
  ];
  return (
    <div className='MultiselectWithApi' ref={wrapperRef}>
      <ReactSelectDropDown options={dropDownOption} onMenuScrollToBottom={scrollReachedToBottom} />
      {isDropDownFixed === false ? (
        <div className='optionOuterContainer'>
          <div
            className={`optionsContainer ${isDivOpen ? 'open' : ''}`}
            onScroll={(e) => handleScroll(e)}
          >
            <CustomOption options={dropDownOption} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MultiselectWithApi;

const Options = ({
  elem,
  checked,
  index,
  handleSelect,
}: {
  elem: string;
  checked: boolean;
  index: number;
  handleSelect: any;
}) => {
  return (
    <div className='options' onClick={() => handleSelect(elem, checked)}>
      <div className={checked ? 'optionCheckBox checked' : 'optionCheckBox'}>
        {checked && <IoMdCheckmark />}
      </div>
      <div className='title'>{elem}</div>
    </div>
  );
};

let timer: any;

const debounce = (func: Function, timeout = 300) => {
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const CustomOption = (props: any) => {
  const { options } = props;

  const optionData = options[0];
  const { isContent, filteredOptions, selectedValues, handleSelect, apiLock, loading } = optionData;

  return (
    <div>
      {/* <div {...innerProps}> */}
      <div>
        {isContent &&
          React.Children.toArray(
            (filteredOptions ?? []).map((elem: string, i: number) => {
              const isChecked = selectedValues.some((selectedElem: string) => {
                return selectedElem === elem;
              });
              return (
                <Options elem={elem} checked={isChecked} index={i} handleSelect={handleSelect} />
              );
            })
          )}
        {apiLock && (
          <div className='options'>
            <div className='title'>{mls('No More Data Found')}</div>
          </div>
        )}
        {loading && !apiLock && (
          <div className='lodingDiv'>
            <img src={loadingImage} alt='' />
          </div>
        )}
      </div>
    </div>
  );
};

const ValueContainer = (props: any) => {
  const { options } = props;
  const optionData = options[0];
  const {
    handleEnter,
    textInput,
    searchInput,
    handleFocus,
    selectedValues,
    handleSearchInput,
    handleSelect,
    inputPlaceholder,
  } = optionData;
  const primitiveValues = [
    {
      name: 'null',
      key: null,
    },
    {
      name: 'false',
      key: false,
    },
    {
      name: 'true',
      key: true,
    },
  ];
  const filteredSelectedData = (selectedValues ?? []).filter(
    (value: any) => value && value !== true
  );

  return (
    <div style={{ width: '100%' }}>
      <div className='inputDiv' onClick={() => handleFocus()}>
        {React.Children.toArray(
          primitiveValues.map((primitiveElem) => {
            const isSelected = (selectedValues ?? []).some(
              (elem: any) => elem === primitiveElem?.key
            );
            // const handlePrimitiveSelection = ({ isSelected, key }: any) => {
            //   // if (isSelected) {
            //   //   const filterdData = (selectedValues ?? []).filter((item) => item !== key);
            //   //   handleColumnSelection(filterdData);
            //   //   return;
            //   // }
            //   // handleColumnSelection([...selectedValues, key]);
            // };
            if (!isSelected) {
              return null;
            }
            return (
              <div
                className={`selectedItems ${isSelected ? 'selectedPrimitiveElem' : ''}`}
                // onClick={() => handlePrimitiveSelection({ isSelected, key: primitiveElem?.key })}
              >
                {primitiveElem.name} &nbsp;
              </div>
            );
          })
        )}
        {React.Children.toArray(
          (filteredSelectedData ?? []).map((elem: string) => {
            return (
              <div className='selectedItems'>
                <div className='title'>{elem}</div>
                <div
                  className='remove'
                  onClick={() => {
                    handleSelect(elem, true);
                  }}
                >
                  <img src={x} alt='' />
                </div>
              </div>
            );
          })
        )}
        <input
          type='text'
          placeholder={inputPlaceholder}
          ref={textInput}
          value={searchInput}
          onKeyDown={(e) => handleEnter(e)}
          onChange={(e) => handleSearchInput(e)}
        />
      </div>
    </div>
  );
};
