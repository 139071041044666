const es = {
  'CSV Import': 'Importar CSV',
  'CSV Export': 'Exportar CSV',
  'Table Options': 'Opciones de Tabla',
  'Import your existing CSV data to this table.': 'Importa tus datos CSV existentes a esta tabla.',
  'Export the filtered CSV data of this table.': 'Exporta los datos CSV filtrados de esta tabla.',
  'Table Setting': 'Configuración de Tabla',
  'Update the columns of this table.': 'Actualiza las columnas de esta tabla.',
  'You don\'t have sufficient rights to perform this action!': '¡No tienes permisos suficientes para realizar esta acción!',
  'Rows deleted successfully': 'Filas eliminadas con éxito',
  'There was a problem while deleting, please try again later.': 'Hubo un problema al eliminar, por favor intenta de nuevo más tarde.',
  'Row was already deleted.': 'La fila ya fue eliminada.',
  'Rows created successfully': 'Filas creadas con éxito',
  'There was a problem while creating row, please try again later!': 'Hubo un problema al crear la fila, ¡intenta de nuevo más tarde!',
  'There was a problem while fetching App Schema, please try again later!': 'Hubo un problema al obtener el esquema de la aplicación, ¡intenta de nuevo más tarde!',
  'Analytics': 'Analítica',
  'Create an Account': 'Crear una Cuenta',
  'Sign In to Supista': 'Iniciar Sesión en Supista',
  'New Here?': '¿Nuevo Aquí?',
  'Clear Filters': 'Limpiar Filtros',
  'Page Size': 'Tamaño de Página',
  'No rows to show': 'No hay filas para mostrar',
  'Sign Out': 'Cerrar Sesión',
  'Language': 'Idioma',
  'Please wait while your rows are loading': 'Por favor, espera mientras se cargan tus filas',
  'Deleting Rows': 'Eliminando Filas',
  'Are you sure to delete the selected items?': '¿Estás seguro de eliminar los elementos seleccionados?',
  // version 2
  'all': 'todo', 
  'today': 'hoy', 
  'this week': 'esta semana',
  'this month': 'este mes',
  'this year': 'este año',
  'custom date': 'fecha personalizada',
  'Filter Date': 'Filtrar Fecha',
  'Select Custom Date': 'Seleccionar Fecha Personalizada',
  'to': 'hasta',
  'CSV File to Upload:': 'Archivo CSV para Cargar:',
  'Drag & drop some files here, or click to select files': 'Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar archivos',
  'Row updated successfully': 'Fila actualizada con éxito',
  'There was a problem while updating row , please try again later!': 'Hubo un problema al actualizar la fila, ¡intenta de nuevo más tarde!',
  Edit: 'Editar',
  // version 3
  ForgotPassword: '¿Olvidaste tu Contraseña?',
  'Page Not Found': 'Página No Encontrada',
  'The page you looked not found!': '¡La página que buscabas no fue encontrada!',
  'Create': 'Crear',
  'Update': 'Actualizar',
  'Click to edit': 'Haz clic para editar',
};

export default es;
