export const selectMenuColorPalettes = [
  // '#FFFF00',
  '#009ef7', //new
  '#c4f119', //new
  '#FF0000',
  '#0000FF',
  '#008000',
  '#00FFFF',
  '#008080',
  '#3F00FF',
  '#000080',
  '#800020',
  '#800080',
  '#FF00FF',
  // '#FFA500',
  '#0d76cf', //new
  '#E0B0FF',
  '#7F00FF',
  // '#FFD700',
  '#cf30c2', //new
];
