import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import AddTeamsPopup from '../../AddTeamsPopup/AddTeamsPopup';
import { getAppComponentColumnSchema } from '../PDFTemplates/helperFuntion';
import RoleComponentFilter from '../../RoleComponentFilter/RoleComponentFilter';
import MultiSelect from 'lib/reusable-components/Components/Multiselect/MultiSelect';
import { SolidColorPopupWrapper } from 'lib/reusable-components/Components/SolidColorSelector/SolidColorSelector';

const QueryContainerPermissionContainer = ({
  componentSchema,
  title,
  setTitle,
  activeButtonKey,
  errors,
  setErrors,
  selectedCode,
  udpateCodeCustomization,
  selectedTable,
  connectedTablesData,
}: {
  componentSchema: any;
  title: string;
  setTitle: Function;
  activeButtonKey: string;
  setComponentSchema: Function;
  errors: any;
  connectedTablesData: any;
  setErrors: Function;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  udpateCodeCustomization: Function;
}) => {
  const columnSchema = useMemo(() => {
    return getAppComponentColumnSchema({
      connectedTableList: componentSchema?.connectedTableList ?? [],
      connectedTablesData,
    });
  }, [componentSchema?.connectedTableList, connectedTablesData]);

  const validateFields = () => {
    const errors: { [key: string]: any } = {};
    if (!title.trim()) {
      errors.title = 'Label is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleLabelChange = (e: any) => {
    setTitle(e.target.value);
  };
  const [isDisplayWhenPopup, setIsDisplayWhenPopup] = useState(false);
  const [isDisplayToPopup, setIsDisplayToPopup] = useState(false);
  const handleFilterPopup = () => {
    setIsDisplayWhenPopup(!isDisplayWhenPopup);
  };
  const handleDisplayToPopup = () => {
    setIsDisplayToPopup(!isDisplayToPopup);
  };
  const udpateActionButtonData = ({
    activeButtonKey,
    activeButtonData,
  }: {
    activeButtonKey: string;
    activeButtonData: { [key: string]: any };
  }) => {
    udpateCodeCustomization({
      selectedTable,
      codeCustomizationData: {
        ...selectedCode,
        actionButton: {
          ...(selectedCode?.actionButton ?? {}),
          [activeButtonKey]: activeButtonData,
        },
      },
    });
  };
  const handleSaveActionButtonTitle = () => {
    if (validateFields()) {
      udpateActionButtonData({
        activeButtonKey,
        activeButtonData: {
          ...(actionButton?.[activeButtonKey] ?? {}),
          title,
        },
      });
    }
  };
  const actionButton = componentSchema?.codeCustomization?.[selectedTable]?.actionButton ?? {};
  const handleDisplayWhen = (displayWhen: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        displayWhen,
      },
    });
  };
  const handleDisplayTo = (displayTo: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        displayTo,
      },
    });
  };
  const filterLength = ({ filter }: { filter: any }) => {
    const filterLength = Object.keys(filter)?.length;

    if (filterLength > 0) {
      return <div className={styles.filterNumber}>{filterLength}</div>;
    }
    return '';
  };
  const handleActionType = (actionType: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        actionType,
      },
    });
  };
  const handleActionButtonColor = (actionButtonColor: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        actionButtonColor,
      },
    });
  };
  return (
    <>
      <div className={styles.queryContainerPermissionOuterContainer}>
        {isDisplayToPopup ? (
          <AddTeamsPopup
            isDisplayToPopup={isDisplayToPopup}
            setIsDisplayToPopup={setIsDisplayToPopup}
            setSelectedTeams={handleDisplayTo}
            selectedTeams={actionButton?.[activeButtonKey]?.displayTo ?? []}
          />
        ) : null}
        {isDisplayWhenPopup ? (
          <RoleComponentFilter
            setChartFilter={handleDisplayWhen}
            allTableData={columnSchema}
            chartFilter={actionButton?.[activeButtonKey]?.displayWhen ?? {}}
            appID={''}
            sheetID={''}
            isJoinedTable={false}
            isPopUp={isDisplayWhenPopup}
            setIsPopUp={setIsDisplayWhenPopup}
          />
        ) : null}
        <div className={styles.queryContainerPermissionContainer}>
          <Form.Group controlId='actionButtonLabel' className={styles.actionButtonOptions}>
            <Form.Label className={styles.actionButtonOptionsLabel}>
              {mls('Action Name')}:
            </Form.Label>
            <Form.Control
              type='text'
              value={title}
              onChange={handleLabelChange}
              onBlur={() => handleSaveActionButtonTitle()}
              isInvalid={!!errors?.title}
            />
            <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
          </Form.Group>
          <div className={styles.displayWhenFilterContainer}>
            <b>{mls('Display When')}:</b>
            <div className={styles.filterIconContainer}>
              <button
                className='primaryButton secondaryButtonColor'
                onClick={() => {
                  handleFilterPopup();
                }}
              >
                {mls('Filters')}
              </button>
              {filterLength({ filter: actionButton?.[activeButtonKey]?.displayWhen ?? {} })}
            </div>
          </div>

          {/* <div className={styles.displayWhenFilterContainer}>
            <b>{mls('Action Type')}:</b> &nbsp;
            <MultiSelect
              options={[
                actionButtonType.codeType,
                actionButtonType.pdfType,
                actionButtonType.emailType,
              ]}
              selectedValues={
                actionButton?.[activeButtonKey]?.actionType ?? [actionButtonType.codeType.key]
              }
              onSelect={handleActionType}
              onRemove={handleActionType}
              // onUpdate={handleActionType}
              optionType={'objectInArray'}
              isSearch={false}
            />
          </div>
          <div className={styles.displayWhenFilterContainer}>
            <b>{mls('Button Color')}:</b> &nbsp;
            <div>
              <SolidColorPopupWrapper
                color={{ color: actionButton?.[activeButtonKey]?.actionButtonColor }}
                setColor={(e: any) => {
                  console.log(e);
                  handleActionButtonColor(e?.color);
                }}
              />
            </div>
          </div> */}
          <div>
            <b>{mls('Display to')}:</b> &nbsp;
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => handleDisplayToPopup()}
            >
              {mls('Select Teams')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryContainerPermissionContainer;

const actionButtonType = {
  codeType: {
    key: 'codeType',
    name: 'Code Type',
  },
  pdfType: {
    key: 'pdfType',
    name: 'PDF Type',
  },
  emailType: {
    key: 'emailType',
    name: 'Email Type',
  },
};
