import {
  buttonTypeObject,
  crudOpType,
  crudTypeButtonObject,
  defaultCode,
  defaultValidationCode,
} from '../data/appComponentData';
import { v4 as uuid } from 'uuid';

export const getDefaultCode = ({
  componentSchema,
  actionButtonKey,
  buttonType,
  selectedTable,
}: {
  componentSchema: { [key: string]: any };
  actionButtonKey: string;
  buttonType: string;
  selectedTable: string;
}) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const crudOpData = selectedCode?.crudOp ?? {};
  const formValidationData = selectedCode?.formValidation ?? {};
  const actionButtonData = selectedCode?.actionButton ?? {};
  if (buttonType === buttonTypeObject.CRUD.key) {
    if (crudOpData?.[actionButtonKey]?.type === crudOpType.CUSTOM.key) {
      return crudOpData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'displayFunc',
      functionID: functionID,
      functionCode: defaultCode,
      params: [],
      type: crudOpType.CUSTOM.key,
    };
    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.formValidation.key) {
    if (formValidationData?.type === crudOpType.CUSTOM.key) {
      return formValidationData;
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'displayFunc',
      functionID: functionID,
      functionCode: defaultValidationCode,
      displayWhen: {},
      displayTo: [],
      title: 'New Action',
    };

    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    if (actionButtonData?.[actionButtonKey]) {
      return actionButtonData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'actionFunc',
      functionID: functionID,
      functionCode: defaultCode,
      displayWhen: {},
      displayTo: [],
      title: 'New Action',
    };

    return updatedCrudFunction;
  }
};
export const getDefaultInput = ({
  connectedTablesData,
  selectedTable,
  buttonType,
  parentId,
  selectedId,
  actionButtonKey,
}: {
  connectedTablesData: { [key: string]: any };
  selectedTable: string;
  buttonType: string;
  parentId: string | number;
  selectedId: string | number;
  actionButtonKey: string;
}) => {
  const selectedTableData = connectedTablesData[selectedTable];
  const isRelationID = selectedTableData?.relationType !== 'MAIN';
  const relationID = selectedTableData?.relationID;

  if (buttonType === buttonTypeObject.CRUD.key) {
    if (actionButtonKey === crudTypeButtonObject.POST.key) {
      if (isRelationID) {
        return `{
    "__d3__newData": {},
    "__d3__parentId": ${parentId ?? 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__newData": {}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.GET.key) {
      if (isRelationID) {
        return `{
    "__d3__filterdata": {"where": {}},
    "__d3__parentId": ${parentId ?? 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__filterdata": {"where": {}}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.PUT.key) {
      if (isRelationID) {
        return `{
    "__d3__newData": {},
    "__d3__id": ${selectedId ?? ''},
    "__d3__parentId": ${parentId ?? 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__newData": {},
      "__d3__id": ${selectedId ?? ''}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.DELETE.key) {
      if (isRelationID) {
        return `{
    "__d3__parentId": ${parentId ?? 0},
    "__d3__id": ${selectedId ?? ''},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
    "__d3__id": ${selectedId ?? ''}
}`;
    }
  } else if (buttonType === buttonTypeObject.formValidation.key) {
    if (isRelationID) {
      return `{
  "__d3__validateData": {},
  "__d3__parentId": ${parentId ?? ''},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__validateData": {}
}`;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    if (isRelationID) {
      return `{
  "__d3__newData": {},
  "__d3__parentId": ${parentId ?? 0},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__newData": {}
}`;
  }
  return '{}';
};
export const getDefaultGeneratedCode = ({
  componentSchema,
  selectedTable,
}: {
  componentSchema: { [key: string]: any };
  selectedTable: string;
}) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const crudOpData = selectedCode?.crudOp ?? {};
  const formValidationData = selectedCode?.formValidation ?? {};
  const actionButtonData = selectedCode?.actionButton ?? {};
  const finalCode = {
    POST:
      crudOpData?.POST?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.POST,
    GET:
      crudOpData?.GET?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.GET,
    DELETE:
      crudOpData?.DELETE?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.DELETE,
    PUT:
      crudOpData?.PUT?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.PUT,
    [buttonTypeObject.formValidation.key]:
      formValidationData?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : formValidationData,
    actionButton: actionButtonData ?? {},
  };
  return finalCode;
};
export const getIDColumnName = (tableID: string) => {
  return `__d3id__${tableID}Id`;
};
