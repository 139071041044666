import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Task } from './kanbanTypes';
import styles from './kanban.module.scss';

interface TaskItemProps {
  task: Task;
  index: number;
  component: any;
}

const TaskItem: React.FC<TaskItemProps> = ({ task, index, component }) => {
  const kanbanSetting = component?.kanbanSetting ?? {};
  const titleField = kanbanSetting?.titleField ?? '';

  return (
    <Draggable draggableId={`${task?.id}`} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
          }}
          className={styles.kanbanCardTask}
        >
          {task?.[titleField as keyof typeof task]}
        </div>
      )}
    </Draggable>
  );
};

export default TaskItem;
