import React, { useEffect, useRef, useState } from 'react';
import './Multiselect.scss';
// import x from '../../assets/x.svg';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
// import useUpdateEffect from '../../../helpers/hooks/useUpdateEffect';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import ReactSelectDropDown from '../DropDowns/ReactSelectDropDown';
import { FaCaretDown } from 'react-icons/fa';
interface props {
  options: any[];
  onSelect: Function;
  onRemove: Function;
  onUpdate?: Function;
  selectedValues: any;
  show?: boolean;
  validation?: Function;
  chipStyle?: 'defaultChip' | 'borderChip';
  optionType?: 'string' | 'objectInArray';
  nameKey?: string;
  mainKey?: string;
  isDropDownFixed?: boolean;
  placeholder?: string;
  isSearch?: boolean;
}

const MultiSelect = (props: props) => {
  const {
    options = [],
    onSelect = () => {},
    onRemove = () => {},
    onUpdate = () => {},
    selectedValues,
    show = false,
    validation = () => ({ skip: false }),
    chipStyle = 'defaultChip',
    optionType = 'string',
    nameKey = 'name',
    mainKey = 'key',
    isDropDownFixed = true,
    placeholder = 'Select',
    isSearch = true,
  } = props;

  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);
  const [searchInput, setsearchInput] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const textInput = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const handleDiv = () => {
    setisDivOpen(!isDivOpen);
  };
  const handleFocus = () => {
    setisDivOpen(true);
    textInput.current.focus();
  };
  const handleSelect = (target: any, ischecked: boolean) => {
    if (ischecked) {
      const removedElem = selectedValues.filter((elem: any) => {
        if (optionType === 'objectInArray') {
          return elem !== target?.[mainKey];
        }
        return elem !== target;
      });
      onRemove(removedElem);
      onUpdate({ isRemove: true, target });
      return { skip: true };
    }
    const isValid = validation(target);
    if (isValid.skip) {
      return { skip: true };
    }
    const finalData = optionType === 'objectInArray' ? target?.[mainKey] ?? '' : target;
    onSelect([...selectedValues, finalData]);
    onUpdate({ isRemove: false, target });
    return { skip: false };
  };

  const handleSearchInput = (e: any) => {
    setsearchInput(e.target.value);
  };
  const handleSearch = (target: string) => {
    const filtered = (options || []).filter((elem: any) => {
      if (optionType === 'objectInArray') {
        return (elem?.[nameKey] ?? '').toLowerCase().includes(target.toLowerCase());
      }
      return elem.toLowerCase().includes(target.toLowerCase());
    });
    setFilteredOptions(filtered);
  };
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSelect(searchInput, false);
      setsearchInput('');
    }
    if (e.key === 'Backspace') {
      let str = searchInput ?? '';
      str = str.substring(0, str.length - 1);
      setsearchInput(str);
    }
  };
  useUpdateEffect(() => {
    if (!isDivOpen) {
      if (searchInput) {
        const isSkip = handleSelect(searchInput, false);
        if (!isSkip.skip) {
          setsearchInput('');
        }
      }
      const timer = setTimeout(() => setisContent(isDivOpen), 300);
      return () => {
        clearTimeout(timer);
      };
    }
    setisContent(isDivOpen);
  }, [isDivOpen]);

  useUpdateEffect(() => {
    handleSearch(searchInput);
  }, [searchInput]);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  useUpdateEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => handleDiv(), 10);
      }
    }

    if (isDivOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isDivOpen]);

  const dropDownOption = [
    {
      value: 'chocolate',
      label: 'Chocolate',

      nameKey,
      isContent,
      filteredOptions,
      selectedValues,
      optionType,
      mainKey,
      handleSelect,
      //New
      handleEnter,
      textInput,
      searchInput,
      chipStyle,
      handleFocus,
      handleSearchInput,
      selectedOptions: options,
      isSearch,
      //Extra
      dropDownData: { isOpen: isDivOpen },
      CustomOption,
      ValueContainer,
      placeholder,
    },
  ];
  return (
    <div className='CustomMultiselect' ref={wrapperRef}>
      <ReactSelectDropDown options={dropDownOption} />
      {isDropDownFixed === false ? (
        <div className={`dropDownArray ${isDivOpen ? 'open' : ''}`}>
          <CustomOption options={dropDownOption} />
        </div>
      ) : null}
    </div>
  );
};

export default MultiSelect;

const Options = ({
  elem,
  checked,
  index,
  handleSelect,
  optionType,
  nameKey,
  mainKey,
}: {
  elem: any;
  checked: boolean;
  index: number;
  handleSelect: any;
  optionType: string;
  nameKey: string;
  mainKey: string;
}) => {
  return (
    <div className='options' onClick={() => handleSelect(elem, checked)}>
      <div className={checked ? 'optionCheckBox checked' : 'optionCheckBox'}>
        {checked && <IoMdCheckmark />}
      </div>
      <div className='title'>{optionType === 'objectInArray' ? elem?.[nameKey] ?? '' : elem}</div>
    </div>
  );
};

let timer: any;

const debounce = (func: Function, timeout = 300) => {
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
function hexToRgbA(hex: any, opacity: any) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  } else return 'rgba(0,0,0,' + opacity + ')';
}

const getDataUsingKey = ({
  optionType,
  elem,
  options,
  mainKey,
  nameKey,
}: {
  optionType: string;
  elem: string;
  options: any;
  mainKey: string;
  nameKey: string;
}) => {
  if (optionType === 'objectInArray') {
    const finalElem = options.find(
      (optionsElem: { [key: string]: any }) => optionsElem?.[mainKey] === elem
    );
    return { name: finalElem?.[nameKey], mainElem: finalElem };
  }
  return { name: elem, mainElem: elem };
};

const CustomOption = (props: any) => {
  const { options } = props;

  const optionData = options[0];
  const {
    nameKey,
    isContent,
    filteredOptions,
    selectedValues,
    optionType,
    mainKey,
    handleSelect,

    //Extra
    dropDownData,
  } = optionData;

  return (
    <div>
      {/* <div {...innerProps}> */}
      {isContent &&
        React.Children.toArray(
          (filteredOptions || []).map((elem: any, i: number) => {
            const isChecked = selectedValues.some((selectedElem: any) => {
              if (optionType === 'objectInArray') {
                return elem?.[mainKey] === selectedElem;
              }
              return selectedElem === elem;
            });
            if (elem?.__d3__nonDisplayableValue) {
              return <></>;
            }
            return (
              <Options
                elem={elem}
                checked={isChecked}
                index={i}
                handleSelect={handleSelect}
                optionType={optionType}
                nameKey={nameKey}
                mainKey={mainKey}
              />
            );
          })
        )}
      {Object.keys(filteredOptions).length === 0 ? (
        <div className='options' style={{ cursor: 'auto' }}>
          No Options
        </div>
      ) : null}
    </div>
  );
};

const ValueContainer = (props: any) => {
  const { options } = props;
  const optionData = options[0];
  const {
    handleEnter,
    textInput,
    searchInput,
    nameKey,
    chipStyle,
    handleFocus,
    selectedValues,
    optionType,
    mainKey,
    handleSelect,
    handleSearchInput,
    selectedOptions,
    placeholder,
    dropDownData,
    isSearch,
  } = optionData;

  return (
    <div style={{ width: '100%' }}>
      <div className='inputDiv' onClick={() => handleFocus()}>
        {React.Children.toArray(
          (selectedValues ?? []).map((elem: any, i: number) => {
            if (optionType) {
            }

            const { name, mainElem } = getDataUsingKey({
              optionType,
              elem,
              options: selectedOptions,
              mainKey,
              nameKey,
            });
            return (
              <div className={`selectedItems ${chipStyle}`}>
                <div className='title' style={{ fontWeight: 600 }}>
                  {name}
                </div>
                <div
                  className='remove'
                  onClick={() => {
                    handleSelect(mainElem, true);
                  }}
                >
                  <IoMdClose />
                </div>
              </div>
            );
          })
        )}
        {isSearch ? (
          <input
            type='text'
            placeholder={placeholder}
            ref={textInput}
            value={searchInput}
            // onKeyPress={(e) => handleEnter(e)}
            onKeyDown={(e) => handleEnter(e)}
            onChange={(e) => handleSearchInput(e)}
          />
        ) : (
          <>
            {(selectedValues ?? [])?.length === 0 ? (
              <div style={{ height: '2.5rem', display: 'flex', alignItems: 'center' }}>
                {placeholder}
              </div>
            ) : null}
            &nbsp; &nbsp;
          </>
        )}
        <div className={dropDownData.isOpen ? `dropDownarrow open` : 'dropDownarrow'}>
          <FaCaretDown />
        </div>
      </div>
    </div>
  );
};
