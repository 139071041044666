import React, { useState } from 'react';
import KanbanFilter from './KanbanFilter';
import { Form, Col, Row } from 'react-bootstrap';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import styles from './kanban.module.scss';
import { mls } from 'lib/multilanguagesupport';

interface kanbanHeaderProps {
  component: any;
  paginationData: any;
  setPaginationData: Function;
  setFinalFilter: Function;
}
const KanbanHeader = ({
  component,
  paginationData,
  setPaginationData,
  setFinalFilter,
}: kanbanHeaderProps) => {
  const handlePaginationChange = (e: any) => {
    const { name, value } = e.target;
    setPaginationData((prevSchema: any) => ({
      ...prevSchema,
      [name]: Number(value),
      offset: 0,
    }));
  };
  const handleOffset = ({ isAdd }: { isAdd: boolean }) => {
    let offset = paginationData?.offset;
    let count = paginationData?.count;
    if (isAdd) {
      offset = paginationData?.offset + paginationData?.limit;
      if (offset >= count) {
        return;
      }
    } else {
      offset = paginationData?.offset - paginationData?.limit;
      if (offset <= 0) {
        offset = 0;
      }
    }
    setPaginationData((prevSchema: any) => ({
      ...prevSchema,
      offset: offset,
    }));
  };
  const [filterData, setFilterData] = useState({
    popupStatus: false,
    filters: {},
    specialCase: false,
  });
  return (
    <div>
      <KanbanFilter
        component={component}
        filterData={filterData}
        setFilterData={setFilterData}
        setFinalFilter={setFinalFilter}
      />
      <div className={styles.kanbanCompHeader}>
        <button
          type='button'
          className={`${styles.primaryButton} ${styles.secondaryButtonColor} ${styles.secondaryButtonColorWithBorder}`}
          onClick={() => {
            setFilterData({
              ...filterData,
              popupStatus: true,
              specialCase: true,
            });
          }}
        >
          {mls('Filter')}
        </button>
        <Form.Group as={Row} className={styles.limitContainer} controlId='formTable'>
          <Form.Label column sm={7}>
            {mls('Page Size')}:
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              as='select'
              name='limit'
              value={paginationData.limit || ''}
              onChange={handlePaginationChange}
            >
              {React.Children.toArray(
                [10, 50, 100].map((limit: number) => {
                  return <option value={limit}>{limit}</option>;
                })
              )}
            </Form.Control>
          </Col>
        </Form.Group>
        <div className={styles.offsetContainer}>
          <MdArrowBackIos onClick={() => handleOffset({ isAdd: false })} />
          {paginationData?.offset + 1} -{' '}
          {paginationData?.offset + paginationData?.limit >= paginationData?.count
            ? paginationData?.count
            : paginationData?.offset + paginationData?.limit}{' '}
          of {paginationData?.count}
          <MdArrowForwardIos onClick={() => handleOffset({ isAdd: true })} />
        </div>
      </div>
    </div>
  );
};

export default KanbanHeader;
