import React, { useState } from 'react';
import styles from './addTeamsPopup.module.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import './addTeamsPopup.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
const AddTeamsPopup = ({
  isDisplayToPopup,
  setIsDisplayToPopup,
  selectedTeams,
  setSelectedTeams,
}: {
  isDisplayToPopup: boolean;
  setIsDisplayToPopup: Function;
  setSelectedTeams: Function;
  selectedTeams: string[];
}) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appTeams = appSchema?.appErpTeams ?? {};
  const [displayToValues, setDisplayToValues] = useState<string[]>(selectedTeams ?? []);
  const [teamFilter, setTeamFilter] = useState({ search: '' });
  const [filterTeamSchema, setFilterTeamSchema] = useState(appTeams);

  const handleTeamSelection = ({ isSelected, key }: { isSelected: boolean; key: string }) => {
    if (isSelected) {
      const filterdData = (displayToValues ?? []).filter((item) => item !== key);
      setDisplayToValues(filterdData);
      return;
    }
    setDisplayToValues([...displayToValues, key]);
  };
  const handleSearch = (search: string) => {
    setTeamFilter((currentValue) => {
      let filterdData = {};
      Object.keys(appTeams).forEach((appTeamsKey: any) => {
        const appTeamsData = appTeams[appTeamsKey];
        const lowerCaseElemName = appTeamsData.name.toLowerCase();
        const lowerCaseValue = search.toLowerCase();
        if (lowerCaseElemName.includes(lowerCaseValue)) {
          filterdData = { ...filterdData, [appTeamsData.teamID]: appTeamsData };
        }
      });
      setFilterTeamSchema(filterdData);
      return { ...currentValue, search: search };
    });
  };
  const handleClose = () => {
    setSelectedTeams(displayToValues);
    setIsDisplayToPopup(false);
  };
  return (
    <div className={styles.addTeamsPopup}>
      {isDisplayToPopup ? (
        <PopUpDivChild
          initialValue={isDisplayToPopup}
          setPopUp={handleClose}
          popupTitle={mls('Select Teams')}
          className={'displayToMultiselect'}
        >
          <div className={styles.addTeamsContainer}>
            <div className={styles.searchBox}>
              <SearchInputDiv value={teamFilter.search} onChange={handleSearch} fullWidth border />
            </div>
            <div className={styles.addTeamsInnerContainer}>
              {React.Children.toArray(
                Object.keys(filterTeamSchema).map((appTeamsKey, i) => {
                  const appTeamsData = filterTeamSchema[appTeamsKey];
                  const isSelected = (displayToValues ?? []).some((elem) => elem === appTeamsKey);
                  if (
                    appTeamsData?.teamID === 'Admin' ||
                    appTeamsData?.teamID === 'Creator' ||
                    appTeamsData?.teamID === 'Public'
                  ) {
                    return <></>;
                  }
                  return (
                    <div
                      className={styles.addTeamsContainerElem}
                      onClick={() => handleTeamSelection({ isSelected, key: appTeamsKey })}
                    >
                      <div className={styles.addTeamsContainerElemCheckBox}>
                        <CheckBox checked={isSelected} onClick={() => {}} />
                      </div>
                      <div className={styles.addTeamsContainerElemDetails}>
                        <div className={styles.addTeamsContainerElemName}>
                          {mls(appTeamsData?.name)}
                        </div>
                        <div className={styles.addTeamsContainerElemDescription}>
                          {mls(appTeamsData?.description)}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          {/* <MultiSelect
            options={appTeamsArray}
            onSelect={handleDisplayToValues}
            onRemove={handleDisplayToValues}
            selectedValues={displayToValues}
            optionType={'objectInArray'}
            chipStyle={'borderChip'}
            nameKey={'name'}
            mainKey={'teamID'}
          /> */}
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default AddTeamsPopup;
