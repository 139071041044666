import { useRef } from 'react';
import Select, { components } from 'react-select';

const ReactSelectDropDown = (props: any) => {
  const { options, onMenuScrollToBottom = () => {} } = props;
  const optionData = options[0];
  const { dropDownData, CustomOption, isDropDownFixed = true } = optionData;
  const defaultComponents = isDropDownFixed ? {} : { Menu: () => null };
  const selectRef = useRef<any>(null);
  return (
    <Select
      {...props}
      ref={selectRef}
      components={{
        ...defaultComponents,
        Option: CustomOption,
        // MenuList: () => null,
        // DropdownIndicator: () => null,
        // IndicatorSeparator: () => null,
        // Input: () => null,
        // ValueContainer: ValueContainer,
        ValueContainer: () => null,
        IndicatorsContainer: () => null,
        Control: Control,
      }}
      styles={{
        menuList: (base: any) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        control: (base: any) => ({
          ...base,
          border: 'none',
          background: 'transparent',
          padding: '0',
          minHeight: '0px',
        }),
        menuPortal: (base: any) => ({
          ...base,
          minWidth: '120px',
          zIndex: 20,
        }),

        // menu: (base: any) => (true ? { ...base, marginTop: 0 } : { ...base, marginTop: '3rem' }),
      }}
      onMenuScrollToBottom={() => {
        onMenuScrollToBottom && onMenuScrollToBottom();
      }}
      // menuPosition='fixed'
      // menuPlacement='top'
      // isMulti
      // menuPortalTarget={document.body}
      menuPlacement='auto'
      menuPosition='fixed'
      onChange={() => selectRef?.current?.blur()}
      menuIsOpen={dropDownData?.isOpen}
    />
  );
};

export default ReactSelectDropDown;
const Control = ({ children, ...props }: any) => {
  const { options } = props;
  const optionData = options[0];
  const { ValueContainer } = optionData;

  return (
    <components.Control {...props}>
      {ValueContainer ? <ValueContainer options={props.options} /> : null}
      {children}
    </components.Control>
  );
};
