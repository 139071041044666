import React, { useState } from 'react';
import styles from './addTeamsPopup.module.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import './addTeamsPopup.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import { defaultTeamAccessObject, relationTypeObjects } from '../data/appComponentData';
const RoleAccessAddTeamsPopup = ({
  isDisplayToPopup,
  setIsDisplayToPopup,
  selectedTeams,
  setSelectedTeams,
  roleAccessSchema,
  setRoleAccessSchema,
  selectedTableData,
  newConnectedTable,
  componentSchema,
  setComponentSchema,
  connectedTablesData,
}: {
  isDisplayToPopup: boolean;
  setIsDisplayToPopup: Function;
  setSelectedTeams: Function;
  selectedTeams: string[];
  roleAccessSchema: { [key: string]: any };
  setRoleAccessSchema: Function;
  selectedTableData: { [key: string]: any };
  newConnectedTable: { [key: string]: any };
  componentSchema: { [key: string]: any };
  connectedTablesData: { [key: string]: any };
  setComponentSchema: Function;
}) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appTeams = appSchema?.appErpTeams ?? {};
  const [displayToValues, setDisplayToValues] = useState<string[]>(selectedTeams ?? []);
  const [teamFilter, setTeamFilter] = useState({ search: '' });
  const [filterTeamSchema, setFilterTeamSchema] = useState(appTeams);

  const handleTeamSelection = ({ isSelected, key }: { isSelected: boolean; key: string }) => {
    if (isSelected) {
      const filterdData = (displayToValues ?? []).filter((item) => item !== key);
      setDisplayToValues(filterdData);
      return;
    }
    setDisplayToValues([...displayToValues, key]);
  };
  const handleSearch = (search: string) => {
    setTeamFilter((currentValue) => {
      let filterdData = {};
      Object.keys(appTeams).forEach((appTeamsKey: any) => {
        const appTeamsData = appTeams[appTeamsKey];
        const lowerCaseElemName = appTeamsData.name.toLowerCase();
        const lowerCaseValue = search.toLowerCase();
        if (lowerCaseElemName.includes(lowerCaseValue)) {
          filterdData = { ...filterdData, [appTeamsData.teamID]: appTeamsData };
        }
      });
      setFilterTeamSchema(filterdData);
      return { ...currentValue, search: search };
    });
  };
  const getTeamSchemaObject = ({
    teamKeyArray,
    defaultTeamType,
    relationType,
    roleAccessSchema,
    selectedColumnSchema,
  }: {
    teamKeyArray: string[];
    defaultTeamType: string;
    relationType: string;
    roleAccessSchema: { [key: string]: any };
    selectedColumnSchema: any[];
  }) => {
    const columnWiseRoles = selectedColumnSchema?.map((columnElem: any) => columnElem?.columnName);

    let teamObject: { [key: string]: any } = {};
    (teamKeyArray ?? []).forEach((teamsKey: string) => {
      const teamElem = roleAccessSchema[teamsKey];
      if (teamElem) {
        teamObject = { ...teamObject, [teamsKey]: teamElem };
      } else {
        if (relationType === relationTypeObjects.belongsTo.key) {
          teamObject = {
            ...teamObject,
            [teamsKey]: {
              // C: {
              //   ColumnWiseRoles: columnWiseRoles,
              // },
              R: {
                ColumnWiseRoles: columnWiseRoles,
              },
              // U: {
              //   ColumnWiseRoles: columnWiseRoles,
              // },
              // D: {
              //   ColumnWiseRoles: columnWiseRoles,
              // },
            },
          };
        } else if (relationType === relationTypeObjects.belongsToMany.key) {
          teamObject = {
            ...teamObject,
            [teamsKey]: {
              C: {
                ColumnWiseRoles: columnWiseRoles,
              },
              R: {
                ColumnWiseRoles: columnWiseRoles,
              },
              // U: {
              //   ColumnWiseRoles: columnWiseRoles,
              // },
              D: {
                ColumnWiseRoles: columnWiseRoles,
              },
            },
          };
        } else {
          teamObject = {
            ...teamObject,
            [teamsKey]: {
              C: {
                ColumnWiseRoles: columnWiseRoles,
              },
              R: {
                ColumnWiseRoles: columnWiseRoles,
              },
              U: {
                ColumnWiseRoles: columnWiseRoles,
              },
              D: {
                ColumnWiseRoles: columnWiseRoles,
              },
            },
          };
        }
      }
    });
    return teamObject;
  };
  const handleClose = () => {
    if (selectedTableData?.relationType === 'MAIN') {
      let roleAccessData: { [key: string]: any } = {};
      Object.keys(newConnectedTable)?.forEach((connectedTableKey) => {
        const connectedTableElem = connectedTablesData[connectedTableKey];
        const selectedTableId = connectedTableElem?.tableID;
        const roleAccess = componentSchema?.roleAccess ?? {};
        const selectedRoleAccessData = roleAccess?.[selectedTableId] ?? {};
        const teamObject = getTeamSchemaObject({
          teamKeyArray: displayToValues,
          defaultTeamType: defaultTeamAccessObject.type,
          roleAccessSchema: selectedRoleAccessData,
          selectedColumnSchema: connectedTableElem?.columnSchema ?? [],
          relationType: connectedTableElem?.relationType ?? '',
        });
        roleAccessData = {
          ...roleAccessData,
          [selectedTableId]: teamObject,
        };
      });
      setComponentSchema({
        ...componentSchema,
        roleAccess: roleAccessData,
      });
    } else {
      const teamObject = getTeamSchemaObject({
        teamKeyArray: displayToValues,
        defaultTeamType: defaultTeamAccessObject.type,
        roleAccessSchema: roleAccessSchema,
        selectedColumnSchema: selectedTableData?.columnSchema ?? [],
        relationType: selectedTableData?.relationType ?? '',
      });

      setRoleAccessSchema(teamObject);
    }

    setSelectedTeams(displayToValues);
    setIsDisplayToPopup(false);
  };

  return (
    <div className={styles.addTeamsPopup}>
      {isDisplayToPopup ? (
        <PopUpDivChild
          initialValue={isDisplayToPopup}
          setPopUp={handleClose}
          popupTitle={mls('Select Teams')}
          className={'displayToMultiselect'}
        >
          <div className={styles.addTeamsContainer}>
            <div className={styles.searchBox}>
              <SearchInputDiv value={teamFilter.search} onChange={handleSearch} fullWidth border />
            </div>
            <div className={styles.addTeamsInnerContainer}>
              {React.Children.toArray(
                Object.keys(filterTeamSchema).map((appTeamsKey) => {
                  const appTeamsData = filterTeamSchema[appTeamsKey];
                  const isSelected = (displayToValues ?? []).some((elem) => elem === appTeamsKey);
                  if (
                    appTeamsData?.teamID === 'Admin' ||
                    appTeamsData?.teamID === 'Creator' ||
                    appTeamsData?.teamID === 'Public'
                  ) {
                    return <></>;
                  }
                  if (selectedTableData?.relationType !== 'MAIN') {
                    const mainTableIdSelectedTeamList = Object.keys(
                      componentSchema?.roleAccess?.[componentSchema?.table ?? ''] ?? {}
                    );
                    const isTeamInMainTable = (mainTableIdSelectedTeamList ?? []).some(
                      (teamID) => teamID === appTeamsData?.teamID
                    );
                    if (!isTeamInMainTable) {
                      return <></>;
                    }
                  }
                  return (
                    <div
                      className={styles.addTeamsContainerElem}
                      onClick={() => handleTeamSelection({ isSelected, key: appTeamsKey })}
                    >
                      <div className={styles.addTeamsContainerElemCheckBox}>
                        <CheckBox checked={isSelected} onClick={() => {}} />
                      </div>
                      <div className={styles.addTeamsContainerElemDetails}>
                        <div className={styles.addTeamsContainerElemName}>
                          {mls(appTeamsData?.name)}
                        </div>
                        <div className={styles.addTeamsContainerElemDescription}>
                          {mls(appTeamsData?.description)}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default RoleAccessAddTeamsPopup;
