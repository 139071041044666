import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { mls } from 'lib/multilanguagesupport';
import { componenetType } from '../../data/appComponentData';
import CalendarSettings from './CalendarSettings';
import KanbanSettings from './KanbanSettings';

const ComponentDetails = ({
  schema,
  setSchema,
  errors,
  handleBlur,
  appDatatable,
  handleSave,
  isNew,
  appID,
  isComponetDetailsChange,
  setIsComponetDetailsChange,
  connectedTablesData,
  setConnectedTableList,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsComponetDetailsChange(true);
    setSchema((prevSchema) => {
      const newData = {
        ...prevSchema,
        [name]: value,
      };
      if (name === 'type' || name === 'table') {
        delete newData?.calendarSetting;
        delete newData?.roleAccess;
        delete newData?.codeCustomization;
      }
      return newData;
    });
  };

  return (
    <Form>
      <Form.Group as={Row} className='mb-3' controlId='formName'>
        <Form.Label column sm={2}>
          {mls('Name')}:
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type='text'
            name='name'
            value={schema.name || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3' controlId='formDescription'>
        <Form.Label column sm={2}>
          {mls('Description')}:
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as='textarea'
            rows={3}
            name='description'
            value={schema.description || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3' controlId='formTable'>
        <Form.Label column sm={2}>
          {mls('Component Type')}:
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as='select'
            name='type'
            value={schema.type || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.type}
          >
            <option value=''>Select Table Type</option>
            {React.Children.toArray(
              Object.keys(componenetType).map((tableTypeID) => {
                const tableTypeData = componenetType?.[tableTypeID] ?? {};
                return <option value={tableTypeID}>{tableTypeData?.name ?? ''}</option>;
              })
            )}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>{errors.table}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3' controlId='formTable'>
        <Form.Label column sm={2}>
          {mls('Connected Table')}:
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as='select'
            name='table'
            value={schema.table || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.table}
          >
            <option value=''>{mls('Select Table')}</option>
            {React.Children.toArray(
              Object.keys(appDatatable ?? {}).map((tableID) => {
                const tableData = appDatatable?.[tableID] ?? {};
                return <option value={tableID}>{tableData?.name ?? ''}</option>;
              })
            )}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>{errors.table}</Form.Control.Feedback>
        </Col>
      </Form.Group>
      <CalendarSettings
        schema={schema}
        appDatatable={appDatatable}
        handleBlur={handleBlur}
        errors={errors}
        setIsComponetDetailsChange={setIsComponetDetailsChange}
        setSchema={setSchema}
      />
      {schema.type === componenetType.kanban.key ? (
        <KanbanSettings
          schema={schema}
          appDatatable={appDatatable}
          handleBlur={handleBlur}
          errors={errors}
          setIsComponetDetailsChange={setIsComponetDetailsChange}
          setSchema={setSchema}
          appID={appID}
        />
      ) : null}
      <br />
      {isNew || isComponetDetailsChange ? (
        <button
          onClick={() => handleSave(false)}
          className='primaryButton secondaryButtonColor'
          type='button'
        >
          {mls('Save')}
        </button>
      ) : null}
    </Form>
  );
};

export default ComponentDetails;
