import React, { useState, useMemo, useEffect } from 'react';
import AICodeForm from './AICodeForm';
import AIGeneratedCodeEditor from './AIGeneratedCodeEditor';
// import styles from '../../ComponentSettingWrapper.module.scss';
import styles from './codeCustomization.module.scss';
import { _supistaApiPost } from 'lib/server-connection/connections';

import { v4 as uuid } from 'uuid';
import {
  buttonTypeObject,
  crudTypeButtonObject,
  specialDataTypeName,
} from '../../data/appComponentData';
import { mls } from 'lib/multilanguagesupport';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { getDefaultCode, getDefaultGeneratedCode } from '../../utils/componentSettingFunction';

const isCrudCustomizedFunction = ({ componentSchema, selectedTable }) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  return selectedCode ? true : false;
};
const ComponentAiCustomization = ({
  schema,
  componentRowList,
  componentSchema,
  appID,
  appDatatable,
  setComponentSchema,
  connectedTablesData,
}) => {
  const [selectedTable, setSelectedTable] = useState(
    Object.keys(connectedTablesData ?? {})?.[0] ?? ''
  );
  const selectedTableData = connectedTablesData[selectedTable];

  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const [generatedCode, setGeneratedCode] = useState(
    getDefaultGeneratedCode({ componentSchema, selectedTable })
  );

  const [isSchemaInitialized, setIsSchemaInitialized] = useState(
    isCrudCustomizedFunction({ componentSchema, selectedTable })
  );

  const udpateCodeCustomization = ({ selectedTable, codeCustomizationData }) => {
    const updatedComonentSchema = {
      ...(componentSchema ?? {}),
      codeCustomization: {
        ...(componentSchema?.codeCustomization ?? {}),
        [selectedTable]: codeCustomizationData,
      },
    };
    setComponentSchema(updatedComonentSchema);
  };
  const newConnectedTableData = useMemo(() => {
    let tableName = [];
    let columnSchema = [];
    Object.keys(connectedTablesData ?? {}).forEach((connectedTablesKey) => {
      tableName = [...tableName, connectedTablesKey];
      const connectedTablesElem = connectedTablesData[connectedTablesKey];
      const newColumSchema = (connectedTablesElem?.columnSchema ?? []).map((columnElem) => {
        return { ...columnElem, tableID: connectedTablesKey };
      });
      columnSchema = [...columnSchema, ...newColumSchema];
    });
    return {
      tableName: tableName,
      columnSchema: columnSchema,
    };
  }, [connectedTablesData]);

  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);

  const handleGenerateCode = async (requests) => {
    // Initialize default schema
    const baseUrl = `aiQuery/${appID}`;
    const data = {
      queryArray: [
        {
          promptType: 'CRUD',
          query: requests?.create ?? '',
          apiType: 'POST',
        },
        {
          promptType: 'CRUD',
          query: requests?.read ?? '',
          apiType: 'GET',
        },
        {
          promptType: 'CRUD',
          query: requests?.delete ?? '',
          apiType: 'DELETE',
        },
        {
          promptType: 'CRUD',
          query: requests?.update ?? '',
          apiType: 'PUT',
        },
        {
          promptType: 'CRUD',
          query: requests?.formValidation ?? '',
          apiType: 'PUT',
        },
        {
          promptType: 'CRUD',
          query: requests?.actionButtons ?? '',
          apiType: 'PUT',
        },
      ],
      data: {
        tableName: selectedTable,
        columnSchema: newConnectedTableData?.columnSchema,
      },
    };

    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes) {
      const queryData = {
        create: componentRes[0] ?? '',
        read: componentRes[1] ?? '',
        delete: componentRes[2] ?? '',
        update: componentRes[3] ?? '',
        formValidation: componentRes[4] ?? '',
        actionButtons: componentRes[5] ?? '',
      };
      const POSTData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.POST.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const GETData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.GET.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const DELETEData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.DELETE.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const PUTData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.PUT.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const formCustomizationData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: buttonTypeObject.formCustomization.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const actionButtonID = uuid();

      const actionButtonData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: actionButtonID,
        buttonType: buttonTypeObject.actionButton.key,
      });
      let crudOp = {};
      if (selectedTableData?.relationType === specialDataTypeName?.belongsTo?.key) {
        crudOp = {
          GET: { ...GETData, functionCode: queryData?.read?.response ?? '' },
        };
      } else {
        crudOp = {
          POST: { ...POSTData, functionCode: queryData?.create?.response ?? '' },
          GET: { ...GETData, functionCode: queryData?.read?.response ?? '' },
          DELETE: { ...DELETEData, functionCode: queryData?.delete?.response ?? '' },
          PUT: { ...PUTData, functionCode: queryData?.update?.response ?? '' },
        };
      }
      const formValidation = {
        ...formCustomizationData,
        functionCode: queryData?.formCustomization?.response ?? '',
      };
      const actionButton = {
        ...actionButtonData,
        functionCode: queryData?.actionButtons?.response ?? '',
      };
      let codeCustomizationData = {};
      if (selectedTableData?.relationType === specialDataTypeName?.belongsTo?.key) {
        codeCustomizationData = {
          crudOp,
        };
      } else {
        codeCustomizationData = {
          crudOp,
          formValidation,
          actionButton: { [actionButtonID]: actionButton },
        };
      }
      udpateCodeCustomization({
        selectedTable,
        codeCustomizationData: codeCustomizationData,
      });
      setIsAiQueryLoading(false);
      setIsSchemaInitialized(true);
    } else {
      setIsSchemaInitialized(true);
      setIsAiQueryLoading(false);
    }
  };
  const handleAddCodeManually = () => {
    setIsSchemaInitialized(true);
  };
  const handleEditCode = (name, value) => {
    setGeneratedCode((prevCode) => ({
      ...prevCode,
      [name]: { ...prevCode[name], functionCode: value },
    }));
  };
  const handleActionButtonsEdit = (actionButtons) => {
    if (schema) {
      schema.actionButton = actionButtons;
      setGeneratedCode((prevCode) => ({
        ...prevCode,
        actionButton: schema.actionButton,
      }));
    }
  };

  const handleTableSelection = (action) => {
    setSelectedTable(action);
  };
  useEffect(() => {
    setIsSchemaInitialized(isCrudCustomizedFunction({ componentSchema, selectedTable }));
  }, [selectedTable]);

  return (
    <div className={styles.aiCustomization}>
      <div className={styles.selectedTableContainer}>
        <div className={styles.selectedTableTitle}>{mls('Select Table')}</div>
        <div className={styles.selectedTableDropDown}>
          <ObjectDropDown
            object={connectedTablesData}
            dropDownValue={selectedTable}
            mainKey='tableID'
            nameKey='nameWithRelationType'
            setdropDownValue={handleTableSelection}
            ObjectInObject
            inputPlaceHolder={mls('Select Table')}
            search
          />
        </div>
      </div>
      <div className={styles.aiCustomizationInner}>
        {!isSchemaInitialized ? (
          <AICodeForm
            onGenerateCode={handleGenerateCode}
            appID={appID}
            isAiQueryLoading={isAiQueryLoading}
            handleAddCodeManually={handleAddCodeManually}
            componentSchema={componentSchema}
            setComponentSchema={setComponentSchema}
            componentRowList={componentRowList}
            selectedTableData={selectedTableData}
          />
        ) : (
          <AIGeneratedCodeEditor
            schema={{ crudOp: generatedCode }}
            onEdit={handleEditCode}
            onActionButtonsEdit={handleActionButtonsEdit}
            componentSchema={componentSchema}
            setComponentSchema={setComponentSchema}
            appDatatable={appDatatable}
            componentRowList={componentRowList}
            connectedTablesData={connectedTablesData}
            newConnectedTableData={newConnectedTableData}
            appID={appID}
            selectedTable={selectedTable}
            selectedCode={selectedCode}
            selectedTableData={selectedTableData}
            udpateCodeCustomization={udpateCodeCustomization}
          />
        )}
      </div>
    </div>
  );
};

export default ComponentAiCustomization;
