import React from 'react';
import './popUpDiv.scss';
import { FaTimes } from 'react-icons/fa';

const PopUpDiv = ({ className = '', children, initialValue = false, setPopUp }) => {
  const handlePopUp = () => {
    setPopUp(!initialValue);
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`PopUpDiv ${className}`}>
      {initialValue && (
        <div className='popUpOuterContaier' onClick={() => handlePopUp()}>
          <div className='PopUpInnerContaier' onClick={(e) => stopPropagation(e)}>
            <div className='closeButton' onClick={() => handlePopUp()}>
              <FaTimes />
            </div>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpDiv;
