import { erpValidationTypes } from 'lib/erp-data-table/components/data/erpData';

export const getValidationDataByType = ({
  validationType,
  validationData = {
    type: validationType,
    message:
      erpValidationTypes[validationType as keyof typeof erpValidationTypes]?.defaultMessage ?? '',
  },
}: {
  validationType: string;
  validationData?: any;
}) => {
  let newValidation = validationData;
  if (newValidation.type === erpValidationTypes.isLength.key) {
    newValidation = {
      ...newValidation,
      minValue: 0,
      maxValue: 1,
    };
  }

  return { label: '', value: '' };
  return newValidation;
};
export const selectOptionErrorCheck = ({
  field,
  value,
  validation,
  selectMenu,
  originalValue = null,
}: {
  field: string;
  value: any;
  validation: any;
  selectMenu: any;
  originalValue?: null | string;
}) => {
  let error = '';
  let isError = false;
  const currentValidation = { ...validation, [field]: value };
  if (field === 'value') {
    if (!value) {
      error = 'Error Value is required';
    }
    if (originalValue && originalValue === value) {
    } else {
      const isValueAlreadyPresent = (selectMenu ?? []).some(
        (selectMenuElem: any) => selectMenuElem.value === value
      );
      if (isValueAlreadyPresent) {
        error = 'Value must be unique!';
      }
    }
  } else if (field === 'label' && !`${value}`.trim()) {
    error = 'Error Label is required';
  }
  if (error) {
    currentValidation.error = error;
    isError = true;
  } else {
    delete currentValidation.error;
    isError = false;
  }
  return { validtionData: currentValidation, isError: isError };
};
export const checkAllErrorValidtion = ({
  optionData,
  selectMenu,
}: {
  optionData: any;
  selectMenu: any;
}) => {
  const validation = optionData;
  const validationData = getValidationDataByType({ validationType: optionData.type });
  let isError = false;
  let udpatedOptionData = validation;
  Object.keys(validationData ?? {}).some((validationKey) => {
    const validationElem = validation[validationKey];
    const validationErrorData = selectOptionErrorCheck({
      field: validationKey,
      value: validationElem,
      validation,
      selectMenu,
    });
    isError = validationErrorData.isError;
    if (validationErrorData.isError) {
      udpatedOptionData = validationErrorData.validtionData;
    } else {
      udpatedOptionData = validationErrorData.validtionData;
    }

    return validationErrorData.isError;
  });
  return { udpatedOptionData, isError };
};

export const updatedValidaitonOption = ({
  validationOptions,
  localValidationData,
}: {
  validationOptions: any;
  localValidationData: any;
}) => {
  let newTypeObject: any = {};
  Object.keys(validationOptions ?? {}).forEach((typeKey: string) => {
    const typeData = validationOptions[typeKey];
    const isValidationTypeNotPresent = !localValidationData.some((v: any) => {
      return v.type === typeData?.key;
    });
    if (isValidationTypeNotPresent) {
      newTypeObject = { ...newTypeObject, [typeData.key]: typeData };
    }
  });
  return newTypeObject;
};
