import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { componenetType } from '../../data/appComponentData';
import MultiSelect from 'lib/reusable-components/Components/Multiselect/MultiSelect';
import MyCalendar from 'lib/calendar-view/MyCalendar';
import PickColorSettings from './PickColorSettings';

interface calenderSettingsProps {
  schema: any;
  appDatatable: any;
  errors: any;
  handleBlur: any;
  setIsComponetDetailsChange: Function;
  setSchema: Function;
}

const CalenderSettings = ({
  schema,
  appDatatable,
  handleBlur,
  errors,
  setIsComponetDetailsChange,
  setSchema,
}: calenderSettingsProps) => {
  const handleCalendarSettingChange = (e: any) => {
    const { name, value } = e.target;
    setIsComponetDetailsChange(true);
    setSchema((prevSchema: any) => ({
      ...prevSchema,
      calendarSetting: {
        ...(prevSchema?.calendarSetting ?? {}),
        [name]: value,
      },
    }));
  };
  const [isCalender, setIsCalender] = useState(false);
  const [filterList, setFilterList] = useState(
    Object.keys(schema?.calendarSetting?.filterList ?? {})
  );

  const handleFilterUpdate = ({ isRemove, target }: { isRemove: boolean; target: any }) => {
    let filterList = { ...(schema?.calendarSetting?.filterList ?? {}) };
    if (isRemove) {
      delete filterList?.[target?.columnName];
    } else {
      filterList = {
        ...filterList,
        [target?.columnName]: {
          columnName: target?.columnName,
          values: [],
        },
      };
    }
    handleCalendarSettingChange({ target: { value: filterList, name: 'filterList' } });
  };

  const [pickColorData, setPickColorData] = useState({ status: false, columnName: '' });

  const calenderTesting = false;
  return (
    <>
      {pickColorData.status ? (
        <PopUpDivChild
          initialValue={pickColorData.status}
          setPopUp={() => setPickColorData({ status: false, columnName: '' })}
          popupTitle={mls('Pick Color Settings')}
          // fullScreenMode
        >
          <PickColorSettings
            columnName={pickColorData?.columnName}
            filterListData={schema?.calendarSetting?.filterList ?? {}}
            handleSettingChange={handleCalendarSettingChange}
          />
        </PopUpDivChild>
      ) : null}
      {isCalender ? (
        <PopUpDivChild
          initialValue={isCalender}
          setPopUp={() => setIsCalender(false)}
          popupTitle={mls('My Calendar')}
          fullScreenMode
        >
          <MyCalendar
            component={schema ?? {}}
            tableSchema={{}}
            buttonSchema={{}}
            accessSchema={{}}
            parentScreen={{}}
            parentRowID={null}
            relationSchema={{}}
          />
        </PopUpDivChild>
      ) : null}
      {schema.type === componenetType.calendar.key ? (
        <>
          {appDatatable[schema?.table]?.columnSchema ? (
            <>
              <hr />
              <h2> {mls('Calender Settings')} </h2>
              <Form.Group as={Row} className='mb-3' controlId='formTable'>
                <Form.Label column sm={2}>
                  {mls('Title Field')}:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as='select'
                    name='titleField'
                    value={schema?.calendarSetting?.titleField || ''}
                    onChange={handleCalendarSettingChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.titleField}
                  >
                    <option value=''>{mls('Select Title Field')}</option>
                    {React.Children.toArray(
                      (appDatatable[schema?.table]?.columnSchema ?? []).map((columnElem: any) => {
                        return (
                          <option value={columnElem?.columnName}>
                            {columnElem?.name ?? columnElem?.columnName}
                          </option>
                        );
                      })
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>{errors.table}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3' controlId='formTable'>
                <Form.Label column sm={2}>
                  {mls('Start Date')}:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as='select'
                    name='startDate'
                    value={schema?.calendarSetting?.startDate || ''}
                    onChange={handleCalendarSettingChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.startDate}
                  >
                    <option value=''>{mls('Select Start Date')}</option>
                    {React.Children.toArray(
                      (appDatatable[schema?.table]?.columnSchema ?? []).map((columnElem: any) => {
                        return (
                          <option value={columnElem?.columnName}>
                            {columnElem?.name ?? columnElem?.columnName}
                          </option>
                        );
                      })
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>{errors.table}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3' controlId='formTable'>
                <Form.Label column sm={2}>
                  {mls('End Date')}:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as='select'
                    name='endDate'
                    value={schema?.calendarSetting?.endDate || ''}
                    onChange={handleCalendarSettingChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.endDate}
                  >
                    <option value=''>{mls('Select End Date')}</option>
                    {React.Children.toArray(
                      (appDatatable[schema?.table]?.columnSchema ?? []).map((columnElem: any) => {
                        return (
                          <option value={columnElem?.columnName}>
                            {columnElem?.name ?? columnElem?.columnName}
                          </option>
                        );
                      })
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>{errors.table}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-3' controlId='formTable'>
                <Form.Label column sm={2}>
                  {mls('Filter')}:
                </Form.Label>
                <Col sm={10}>
                  <MultiSelect
                    options={appDatatable[schema?.table]?.columnSchema ?? []}
                    onSelect={(value: any) => {
                      setFilterList(value);
                    }}
                    onRemove={(value: any) => {
                      setFilterList(value);
                    }}
                    onUpdate={handleFilterUpdate}
                    selectedValues={filterList}
                    nameKey='name'
                    mainKey={'columnName'}
                    optionType={'objectInArray'}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.table}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <h3>{mls('Filter List')} </h3>
              {React.Children.toArray(
                Object.keys(schema?.calendarSetting?.filterList ?? {}).map((columnName: string) => {
                  // const columnElem = schema?.calendarSetting?.filterList[columnName];
                  return (
                    <Form.Group as={Row} className='mb-3' controlId='formTable'>
                      <Form.Label column sm={2}>
                        {columnName}:
                      </Form.Label>
                      <Col sm={10}>
                        <button
                          onClick={() => setPickColorData({ status: true, columnName: columnName })}
                          className='primaryButton secondaryButtonColor'
                          type='button'
                        >
                          {mls('Pick Colors')}
                        </button>
                      </Col>
                    </Form.Group>
                  );
                })
              )}
              {calenderTesting ? (
                <>
                  <br />
                  <br />
                  <div>
                    <button
                      onClick={() => setIsCalender(true)}
                      className='primaryButton'
                      type='button'
                    >
                      {mls('Open Calender')}
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default CalenderSettings;
