import React, { useEffect, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { erpAllDataType } from '../../data/erpData';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { Form } from 'react-bootstrap';
import styles from './columnSettings.module.scss';

const UserSelection = ({ dataTableSchema, setDataTableSchema, columnData, updateColumnData }) => {
  const [colOptions, setColOptions] = useState([]);

  useEffect(() => {
    const defaultColumn = [
      {
        columnName: 'id',
        dataType: erpAllDataType.ID.key,
        name: 'id',
      },
      {
        columnName: '__d3__createdBy',
        dataType: erpAllDataType.ID.key,
        name: 'Created By',
      },
      {
        columnName: '__d3__lastUpdatedBy',
        dataType: erpAllDataType.ID.key,
        name: 'Last Updated By',
      },
    ];
    let tmp = dataTableSchema.columnSchema
      .filter(
        (data) =>
          data?.columnName !== columnData?.columnName &&
          data.dataType === erpAllDataType.belongsTo.key
      )
      .map((data) => {
        return { value: data.name, label: data.name };
      });
    setColOptions([...defaultColumn, ...tmp]);
  }, []);
  const updateIsMultiple = (action) => {
    updateColumnData({ key: 'isMultiple', value: action });
  };
  const updateIsUnique = (action) => {
    updateColumnData({ key: 'isUnique', value: action });
  };
  const updateIdField = (e) => {
    updateColumnData({ key: 'idField', value: e.target.value });
  };

  return (
    <div className={styles.userOuterContainer}>
      {/* <p className='mb-5 fs-6 fw-bolder text-dark'>{mls('Users')}</p> */}
      <div className={styles.checkBoxOuterContainer}>
        <div className={styles.checkBoxInnerContainer}>
          <CheckBox checked={columnData?.isUnique ?? false} onClick={updateIsUnique} />
          <label className='fs-6 fw-bolder text-dark'>{mls('Is Unique')}</label>
        </div>
        {/* <div className={styles.checkBoxInnerContainer}>
          <CheckBox checked={columnData?.isMultiple ?? false} onClick={updateIsMultiple} />
          <label className='fs-6 fw-bolder text-dark'>{mls('Is Multiple')}</label>
        </div> */}
        <div className={styles.idFieldOuterContainer}>
          <div className={styles.idFieldInnerLable}>
            <label className='fs-6 fw-bolder text-dark'>{mls('Id Field')}:</label>
          </div>
          <div className={styles.idFieldInnerContainer}>
            <Form.Control
              as='select'
              name='table'
              value={columnData.idField || 'id'}
              onChange={updateIdField}
            >
              {React.Children.toArray(
                colOptions.map((colElem) => {
                  return <option value={colElem?.columnName}>{colElem?.name ?? ''}</option>;
                })
              )}
            </Form.Control>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSelection;
