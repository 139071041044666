/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '_metronic/helpers';
import { mls } from 'lib/multilanguagesupport';
import { useState } from 'react';
import UsersList from './UsersList';
// import Permissions from './Permissions';
import { _supistaApiPost } from 'lib/server-connection/connections';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useMessageHandling from 'lib/reusable-components/reusableUtils/commons/useMessageHandling';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';

const EditTeam = ({ appID, teamID, isNew, teamSchema, setEdit, analyticsSchema }) => {
  const toastMsg = useMessageHandling();
  console.log(teamSchema, 'teamSc hema');

  const [newTeamSchema, setTeamSchema] = useState(teamSchema || {});

  const handleBlur = (field, val) => {
    setTeamSchema({ ...newTeamSchema, [field]: val });
  };
  const handleDelete = async () => {
    const endPoint = `auth/${appID}/deleteUsersRoleOfApp`;
    const dataToSend = {
      userIDList: (newTeamSchema.usersList || []).map((user) => user[0]),
      erpRoleToUpdate: teamID,
    };
    const nodeRes = await _supistaApiPost(endPoint, { data: dataToSend });

    if (nodeRes && nodeRes.__d3__success) {
      await publishToProduction(appID);
      toastMsg(
        reduxConstants.config.APP_STATUS.TOAST,
        `${mls('Team deleted successfully')}`,
        5,
        true
      );
      setEdit(null);
    }
  };
  const handleSave = async () => {
    if (newTeamSchema.name) {
      try {
        const currentTimestamp = Date.now();

        const endPoint = `auth/${appID}/updateUsersRoleOfApp`;
        const dataToSend = {
          ...newTeamSchema,
          userIDList: (newTeamSchema.usersList || []).map((user) => user[0]),
          erpRoleToUpdate: teamID,
          createdAt: newTeamSchema?.createdAt || currentTimestamp,
          updatedAt: currentTimestamp,
        };
        const nodeRes = await _supistaApiPost(endPoint, { data: dataToSend });
        if (nodeRes && nodeRes.__d3__success) {
          await publishToProduction(appID);
          toastMsg(
            reduxConstants.config.APP_STATUS.TOAST,
            `${mls('Team saved successfully')}`,
            5,
            true
          );
          setEdit(null);
        } else {
          toastMsg(
            reduxConstants.config.APP_STATUS.ERROR_TOAST,
            `${mls('There was a problem while saving Team, please try again later')}`,
            5,
            true
          );
        }
      } catch (err) {
        toastMsg(
          reduxConstants.config.APP_STATUS.ERROR_TOAST,
          `${mls('There was a problem while saving Team, please try again later')}`,
          5,
          true
        );
      }
    } else {
      toastMsg(reduxConstants.config.APP_STATUS.ERROR_TOAST, `${mls('Name is required')}`, 5, true);
    }
  };
  return (
    <>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <div className='card-title '>
            <div className='card-icon'>
              <i onClick={() => setEdit(null)} className='btn btn-sm btn-light-primary p-3'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr002.svg'
                  className='svg-icon-2 me-0'
                  style={{ color: '#009ef7' }}
                />
              </i>
            </div>
            <h3 className='align-items-start d-flex flex-column'>
              <span className='card-label fw-bolder fs-4 mb-1'>
                {isNew ? mls('Create new team') : mls('Team')}
              </span>
              <span className='text-muted mt-1 fw-bold fs-7'>
                {isNew ? mls('Create team settings') : mls('Edit team settings')}
              </span>
            </h3>
          </div>
          <div className='card-toolbar'>
            {!isNew && teamID !== 'Admin' && teamID !== 'Creator' && (
              <button className='btn btn-sm btn-light-danger me-5' onClick={handleDelete}>
                {mls('Delete')}
              </button>
            )}
            <button className='btn btn-sm btn-light-primary' onClick={handleSave}>
              {mls('Save')}
            </button>
          </div>
        </div>
        <hr />
        <div className='card-body py-3'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='w-100'>
                  <div className='mb-5'>
                    <label className='form-label required'>{mls('Team Name')}</label>

                    <input
                      type='text'
                      name='Team Name'
                      value={newTeamSchema.name || ''}
                      onChange={(e) => handleBlur('name', e?.target.value)}
                      className='form-control form-control-sm form-control-solid'
                    />
                  </div>

                  <div className='mb-5'>
                    <label className='form-label'>
                      <span>{mls('Description')}</span>
                    </label>

                    <input
                      type='text'
                      name='Description'
                      value={newTeamSchema.desc || ''}
                      onChange={(e) => handleBlur('desc', e?.target.value)}
                      className='form-control form-control-sm form-control-solid'
                    />
                  </div>
                </div>
                <hr />
                <h4 className='align-items-start d-flex flex-column'>
                  <span className='card-label fw-bolder fs-5 mb-1'>{mls('Users List')}</span>
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {mls('Add users granting the team permissions')}
                  </span>
                </h4>
                <UsersList
                  appID={appID}
                  isNew={isNew}
                  newTeamSchema={newTeamSchema}
                  setTeamSchema={setTeamSchema}
                />
              </div>
              {/* <div className='col-md-6'>
                <h4 className='align-items-start d-flex flex-column'>
                  <span className='card-label fw-bolder fs-5 mb-1'>{mls('Permissions')}</span>
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {mls('Edit team permissions')}
                  </span>
                </h4>
                <Permissions
                  appID={appID}
                  newTeamSchema={newTeamSchema}
                  setTeamSchema={setTeamSchema}
                  teamID={teamID}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditTeam;
