import React, { useState, useMemo } from 'react';
import { mls } from 'lib/multilanguagesupport';
import styles from '../columnSettings.module.scss';
import {
  checkAllErrorValidtion,
  getValidationDataByType,
  updatedValidaitonOption,
  validationErrorCheck,
} from './validationUtils';
import ValidationFields from './ValidationFields';
const AddValidation = ({
  validationOptions,
  localValidationData,
  setLocalValidationData,
}: {
  validationOptions: any;
  localValidationData: any;
  setLocalValidationData: Function;
}) => {
  const handleAddValidationData = ({ validationData }: { validationData: any }) => {
    setLocalValidationData([...localValidationData, validationData]);
  };
  const [addValidationTempData, setAddValidationTempData] = useState<{
    status: boolean;
    validationData: { [key: string]: any };
  }>({
    status: false,
    validationData: {},
  });

  const newValidationOption = useMemo(
    () =>
      updatedValidaitonOption({
        validationOptions: validationOptions,
        localValidationData: localValidationData,
      }),
    [validationOptions, localValidationData]
  );

  const addValidation = () => {
    if (addValidationTempData.status) {
      const validationErrorData = checkAllErrorValidtion({
        validation: addValidationTempData?.validationData,
      });
      if (validationErrorData.isError) {
        setAddValidationTempData({
          ...addValidationTempData,
          validationData: validationErrorData?.udpatedValidation,
        });
      } else {
        handleAddValidationData({ validationData: validationErrorData?.udpatedValidation });
        setAddValidationTempData({
          ...addValidationTempData,
          status: false,
          validationData: validationErrorData?.udpatedValidation,
        });
      }
    } else {
      const validationOptionArray: any = Object.values(newValidationOption ?? {});
      const newValidation = getValidationDataByType({
        validationType: validationOptionArray?.[0]?.key ?? '',
      });
      setAddValidationTempData({ status: true, validationData: newValidation });
    }
  };
  const handleChangeAddValidation = (e: any, field: any) => {
    e.preventDefault();
    const value = e.target.value;
    const validationErrorData = validationErrorCheck({
      field: field,
      value: value,
      validation: addValidationTempData?.validationData,
    });
    let updatedValidation = validationErrorData.validtionData;
    if (field === 'type') {
      updatedValidation = getValidationDataByType({
        validationType: validationErrorData.validtionData.type,
      });
    } else {
      updatedValidation = getValidationDataByType({
        validationData: validationErrorData.validtionData,
        validationType: validationErrorData.validtionData.type,
      });
    }
    setAddValidationTempData({
      ...addValidationTempData,
      validationData: updatedValidation,
    });
  };
  return (
    <>
      {addValidationTempData.status ? (
        <div className={styles.addValidationContainer}>
          <ValidationFields
            validationData={addValidationTempData?.validationData}
            validationOptions={newValidationOption}
            handleChangeValidation={handleChangeAddValidation}
          />
          <div>
            <button
              className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'
              onClick={(e) => addValidation()}
              type='button'
            >
              {mls('Add validation')}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <button
            className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'
            onClick={(e) => addValidation()}
            type='button'
          >
            {mls('Add validation')}
          </button>
        </div>
      )}
    </>
  );
};

export default AddValidation;
