import React from 'react';
import { mls } from 'lib/multilanguagesupport';
import style from './roleAccess.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { FiFilter } from 'react-icons/fi';
import {
  columnBooleanTypeObject,
  defaultTeamAccessObject,
  filterTypeObject,
} from '../../data/appComponentData';

const getIsAllTrue = ({
  roleAccessElem,
  selectedColumnSchema,
}: {
  roleAccessElem: any;
  selectedColumnSchema: any;
}) => {
  const columnWiseRolesLength = Object.keys(roleAccessElem?.ColumnWiseRoles ?? {})?.length;
  const selectedColumnLength = selectedColumnSchema?.length;
  return columnWiseRolesLength === selectedColumnLength;
};
interface columnWiseRolesContainerProps {
  connectedTablesData: any;
  selectedTeamForColumn: string;
  roleAccessSchema: any;
  setRoleAccessSchema: Function;
  filterData: any;
  setFilterData: Function;
  selectedTableData: { [key: string]: any };
}
const ColumnWiseRolesContainer = ({
  connectedTablesData,
  selectedTeamForColumn,
  roleAccessSchema,
  setRoleAccessSchema,
  setFilterData,
  filterData,
  selectedTableData,
}: columnWiseRolesContainerProps) => {
  const roleAccessElem = roleAccessSchema?.[selectedTeamForColumn];

  if (!roleAccessElem) {
    return <></>;
  }
  const isCreate = getIsAllTrue({
    roleAccessElem: roleAccessElem?.C ?? {},
    selectedColumnSchema: selectedTableData?.columnSchema ?? [],
  });
  const isRead = getIsAllTrue({
    roleAccessElem: roleAccessElem?.R ?? {},
    selectedColumnSchema: selectedTableData?.columnSchema ?? [],
  });
  const isUpdate = getIsAllTrue({
    roleAccessElem: roleAccessElem?.U ?? {},
    selectedColumnSchema: selectedTableData?.columnSchema ?? [],
  });
  const updateTeamAccess = ({
    teamKey,
    teamAccessData,
  }: {
    teamKey: string;
    teamAccessData: any;
  }) => {
    setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: teamAccessData });
  };
  const handleSelection = ({ key, value }: { key: string; value: boolean }) => {
    let finalTeamAccess: { [key: string]: any } = { ...roleAccessElem };
    let tempRoles = [];

    if (value) {
      tempRoles = (selectedTableData?.columnSchema ?? [])?.map(
        (columnElem: any) => columnElem?.columnName
      );
    }
    finalTeamAccess = {
      ...(finalTeamAccess ?? {}),
      [key]: {
        ...(finalTeamAccess?.[key] ?? {}),
        ColumnWiseRoles: tempRoles,
      },
    };
    updateTeamAccess({ teamKey: selectedTeamForColumn, teamAccessData: finalTeamAccess });
  };
  const roleAccessCrudLength = Object.keys(roleAccessElem ?? {}).length;
  const handleFilter = ({
    key,
    disabled,
    selectedTeamsKey,
    uniqueColumnName,
  }: {
    key: string;
    selectedTeamsKey: string;
    uniqueColumnName: string;
    disabled?: boolean;
  }) => {
    if (disabled) {
      return;
    }
    setFilterData({
      ...filterData,
      popupStatus: true,
      filters: roleAccessSchema?.[selectedTeamsKey]?.[key]?.columnFilter?.[uniqueColumnName] ?? {},
      selectedTeamsKey: selectedTeamsKey,
      key: key,
      filterType: filterTypeObject.columnFilter.key,
      uniqueColumnName,
    });
  };
  const filterLength = ({
    key,
    disabled,
    selectedTeamsKey,
    uniqueColumnName,
  }: {
    key: string;
    selectedTeamsKey: string;
    uniqueColumnName: string;
    disabled?: boolean;
  }) => {
    if (disabled) {
      return '';
    }
    const filterLength = Object.keys(
      roleAccessSchema?.[selectedTeamsKey]?.[key]?.columnFilter?.[uniqueColumnName] ?? {}
    )?.length;

    if (filterLength > 0) {
      return (
        <div>
          (<b>{mls('Applied')}</b>: {filterLength})
        </div>
      );
    }
    return '';
  };
  const gridLength = roleAccessCrudLength + (roleAccessElem.D ? -1 : 0);
  return (
    <div className={style.columnWiseRolesGridOuterContainer}>
      <div
        className={style.columnWiseRolesGridInnerContainer}
        style={{
          gridTemplateColumns: `18rem ${gridLength ? `repeat(${gridLength},  auto)` : ''}`,
        }}
      >
        <div
          className={`${style.gridCell} ${style.upperColumnGridCell}  ${style.sideColumnGridCell}  ${style.teamNameColumnGridCell}`}
        >
          {mls('Columns')}
        </div>
        {roleAccessElem?.C ? (
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isCreate}
              onClick={(value: boolean) => {
                handleSelection({ key: 'C', value });
              }}
            />
            {mls('Create')}
          </div>
        ) : null}

        {roleAccessElem?.R ? (
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isRead}
              onClick={(value: boolean) => {
                handleSelection({ key: 'R', value });
              }}
            />
            {mls('Read')}
          </div>
        ) : null}
        {roleAccessElem?.U ? (
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isUpdate}
              disabled={!isRead}
              onClick={(value: boolean) => {
                handleSelection({ key: 'U', value });
              }}
            />
            {mls('Update')}
          </div>
        ) : null}
        <>
          {(selectedTableData?.columnSchema ?? []).map((columnElem: any) => {
            const uniqueColumnName = `${columnElem?.columnName}`;
            const getTrueValue = ({
              key,
              roleAccessElem,
              uniqueColumnName,
            }: {
              key: string;
              roleAccessElem: any;
              uniqueColumnName: string;
            }) => {
              const selectedRoleAccess = roleAccessElem?.[key] ?? {};
              const isSlected = (selectedRoleAccess?.ColumnWiseRoles ?? []).some(
                (columnNameElem: any) => columnNameElem === uniqueColumnName
              );
              return isSlected;
            };
            const isCreate = getTrueValue({
              key: 'C',
              roleAccessElem,
              uniqueColumnName,
            });
            const isRead = getTrueValue({
              key: 'R',
              roleAccessElem,
              uniqueColumnName,
            });
            const isUpdate = getTrueValue({
              key: 'U',
              roleAccessElem,
              uniqueColumnName,
            });
            const handleSelection = ({ key, value }: { key: string; value: boolean }) => {
              let finalTeamAccess: { [key: string]: any } = { ...roleAccessElem };
              let tempRoles = finalTeamAccess?.[key]?.ColumnWiseRoles ?? [];
              if (value) {
                tempRoles = [...tempRoles, uniqueColumnName];
              } else {
                tempRoles = tempRoles?.filter(
                  (columnNameElem: any) => columnNameElem !== uniqueColumnName
                );
              }
              finalTeamAccess = {
                ...finalTeamAccess,
                [key]: {
                  ...(finalTeamAccess?.[key] ?? {}),
                  ColumnWiseRoles: tempRoles,
                },
              };
              updateTeamAccess({
                teamKey: selectedTeamForColumn,
                teamAccessData: finalTeamAccess,
              });
            };

            return (
              <>
                <div className={`${style.gridCell} ${style.sideColumnGridCell}`}>
                  {columnElem?.name}
                </div>
                {roleAccessElem?.C ? (
                  <div
                    className={`${style.gridCell}  ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                  >
                    <CheckBox
                      checked={isCreate}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'C', value });
                      }}
                    />
                  </div>
                ) : null}
                {roleAccessElem?.R ? (
                  <div
                    className={`${style.gridCell}  ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                  >
                    <CheckBox
                      checked={isRead}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'R', value });
                      }}
                    />
                  </div>
                ) : null}

                {roleAccessElem?.U ? (
                  <div
                    className={`${style.gridCell}  ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                  >
                    <CheckBox
                      checked={isUpdate}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'U', value });
                      }}
                      disabled={!isRead}
                    />
                    {/* <FiFilter
                            className={`${style.valueFilterIcon} ${
                              !isRead || !isUpdate ? style.valueGridDisabled : ''
                            }`}
                            onClick={() =>
                              handleFilter({
                                key: 'U',
                                disabled: !isRead || !isUpdate,
                                selectedTeamsKey: selectedTeamForColumn,
                                uniqueColumnName,
                              })
                            }
                          />
                          {filterLength({
                            key: 'U',
                            disabled: !isRead || !isUpdate,
                            selectedTeamsKey: selectedTeamForColumn,
                            uniqueColumnName,
                          })} */}
                  </div>
                ) : null}
              </>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default ColumnWiseRolesContainer;
