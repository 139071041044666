/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import moment from 'moment';

const dateColumnsType = ['date', 'datePicker', '__d3__createdAt', '__d3__updatedAt'];

export const convertFilter = (filterModel, userID) => {
  const whereClause = {};

  Object.keys(filterModel || {}).forEach((columnID) => {
    const columnArr = columnID.split('.');
    const columnName = columnArr.slice(1).join('.');

    if (filterModel[columnID].dataType === 'text' || filterModel[columnID].dataType === 'select') {
      switch (filterModel[columnID]?.value?.condition) {
        case 'contains':
          whereClause[columnName] = { like: `%${filterModel[columnID]?.value?.value}%` };
          break;
        case 'notContains':
          whereClause[columnName] = { notLike: `%${filterModel[columnID?.value?.value]}%` };
          break;
        case 'equals':
          whereClause[columnName] = filterModel[columnID]?.value?.value;
          break;
        case 'notEqual':
          whereClause[columnName] = { ne: filterModel[columnID]?.value?.value };
          break;
        case 'startsWith':
          whereClause[columnName] = { startsWith: filterModel[columnID]?.value?.value };
          break;
        case 'endsWith':
          whereClause[columnName] = { endsWith: filterModel[columnID]?.value?.value };
          break;
        case 'in':
          whereClause[columnName] = { in: filterModel[columnID]?.value?.value };
          break;
        case 'notIn':
          whereClause[columnName] = { notIn: filterModel[columnID]?.value?.value };
          break;
        case 'regexp':
          whereClause[columnName] = { regexp: filterModel[columnID]?.value?.value };
          break;
        default:
          break;
      }
    } else if (
      filterModel[columnID].filterType === 'number' ||
      filterModel[columnID].dataType === 'ID'
    ) {
      switch (filterModel[columnID]?.value?.condition) {
        case 'equals':
          whereClause[columnName] = filterModel[columnID]?.value?.value;
          break;
        case 'notEqual':
          whereClause[columnName] = { ne: filterModel[columnID]?.value?.value };
          break;
        case 'lessThan':
          whereClause[columnName] = { lt: filterModel[columnID]?.value?.value };
          break;
        case 'lessThanOrEqual':
          whereClause[columnName] = { lte: filterModel[columnID]?.value?.value };
          break;
        case 'greaterThan':
          whereClause[columnName] = { gt: filterModel[columnID]?.value?.value };
          break;
        case 'greaterThanOrEqual':
          whereClause[columnName] = { endsWith: filterModel[columnID]?.value?.value };
          break;
        case 'inRange':
          whereClause[columnName] = {
            between: [filterModel[columnID].value?.min, filterModel[columnID]?.value?.max],
          };
          break;
        default:
          break;
      }
    } else if (dateColumnsType.includes(filterModel[columnID].dataType)) {
      const today = moment().startOf('day');
      const value = filterModel[columnID]?.value;
      switch (value?.period) {
        case 'TODAY':
          whereClause[columnName] = {
            gte: today.toDate(),
            lt: today.clone().endOf('day').toDate(),
          };
          break;

        case 'THISWEEK':
          whereClause[columnName] = {
            gte: today.clone().startOf('week').toDate(),
            lt: today.clone().endOf('week').toDate(),
          };
          break;

        case 'THISMONTH':
          whereClause[columnName] = {
            gte: today.clone().startOf('month').toDate(),
            lt: today.clone().endOf('month').toDate(),
          };
          break;

        case 'THISQUARTER':
          whereClause[columnName] = {
            gte: today.clone().startOf('quarter').toDate(),
            lt: today.clone().endOf('quarter').toDate(),
          };
          break;

        case 'THISYEAR':
          whereClause[columnName] = {
            gte: today.clone().startOf('year').toDate(),
            lt: today.clone().endOf('year').toDate(),
          };
          break;

        case 'LAST7DAYS':
          whereClause[columnName] = {
            gte: today.clone().subtract(7, 'days').toDate(),
            lt: today.clone().endOf('day').toDate(),
          };
          break;

        case 'LAST30DAYS':
          whereClause[columnName] = {
            gte: today.clone().subtract(30, 'days').toDate(),
            lt: today.clone().endOf('day').toDate(),
          };
          break;

        case 'LASTXDAYS':
          if (value?.xDay > 0) {
            whereClause[columnName] = {
              gte: today.clone().subtract(value?.xDay, 'days').toDate(),
              lt: today.clone().endOf('day').toDate(),
            };
          }
          break;

        case 'PREV_LASTXDAYS':
          if (value?.xDay > 0) {
            whereClause[columnName] = {
              gte: today
                .clone()
                .subtract(value.xDay * 2, 'days')
                .startOf('day')
                .toDate(),
              lt: today.clone().subtract(value.xDay, 'days').endOf('day').toDate(),
            };
          }
          break;

        case 'CUSTOM':
          whereClause[columnName] = {
            gte: moment(value.startDate).startOf('day').toDate(),
            lt: moment(value.endDate).endOf('day').toDate(),
          };
          break;

        default:
          break;
      }
    } else if (filterModel[columnID].dataType === 'user') {
      const { relationID } = filterModel[columnID];
      if (relationID) {
        whereClause[`${relationID}.${columnName}`] = userID;
      } else {
        whereClause[columnName] = userID;
      }
    }
  });
  console.log(filterModel);
  console.log(whereClause);
  return whereClause;
};
