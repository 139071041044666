import React, { useState } from 'react';
import styles from './PDFTemplate.module.scss';
import CreateTemplate from './CreateTemplate';
import { mls } from 'lib/multilanguagesupport';
import { v4 as uuid } from 'uuid';

const EmailTemplateWrapper = ({
  appDatatable,
  appComponent,
  componentSchema,
  setComponentSchema,
  appID,
}: {
  appDatatable: any;
  appComponent: any;
  componentSchema: any;
  setComponentSchema: Function;
  appID: string;
}) => {
  const [isTemplateSetting, setIsTemplateSetting] = useState(false);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [selectedTemplateID, setSelectedTemplateID] = useState('');
  return (
    <div className={styles.PDFTemplateWrapper}>
      {isTemplateSetting ? (
        <CreateTemplate
          setIsNewTemplate={setIsNewTemplate}
          isNewTemplate={isNewTemplate}
          isTemplateSetting={isTemplateSetting}
          selectedTemplateID={selectedTemplateID}
          setSelectedTemplateID={setSelectedTemplateID}
          setIsTemplateSetting={setIsTemplateSetting}
          appDatatable={appDatatable}
          appComponent={appComponent}
          appID={appID}
          componentSchema={componentSchema}
          setComponentSchema={setComponentSchema}
        />
      ) : (
        <>
          <div className={styles.pdfTemplateWrapperHeaderContainer}>
            <button
              className='primaryButton'
              type='button'
              onClick={() => {
                setIsTemplateSetting(true);
                setIsNewTemplate(true);
                setSelectedTemplateID(uuid());
              }}
            >
              {mls('Create New Template')}
            </button>
          </div>
          <div className={styles.pdfTemplateWrapperCardContainer}>
            {Object.keys(componentSchema?.emailTemplate ?? {})[0]
              ? null
              : mls('Create Email Template for Component')}
            <div className='row'>
              {React.Children.toArray(
                Object.keys(componentSchema?.emailTemplate ?? {}).map(
                  (EmailTemplateKey: string) => {
                    const emailTemplateElem = componentSchema?.emailTemplate[EmailTemplateKey];
                    return (
                      <div
                        // className={`${styles.pdfTemplatesCard} ${styles.pdfTemplateAdd}`}
                        className='col-md-4 col-sm-6 mb-6'
                        onClick={() => {
                          setIsTemplateSetting(true);
                          setIsNewTemplate(false);
                          setSelectedTemplateID(EmailTemplateKey);
                        }}
                      >
                        <div
                          className={`card bgi-no-repeat`}
                          style={{
                            cursor: 'pointer',
                            backgroundPosition: 'right top',
                            backgroundSize: '30% auto',
                            minHeight: 150,
                            // backgroundImage: `url(${toAbsoluteUrl(
                            //   `/media/svg/shapes/abstract-${(index + 1) % 5}.svg`
                            // )})`,
                          }}
                        >
                          <div className='d-flex align-items-center justify-content-end mb-5'>
                            <div className='card-body'>
                              <a
                                href='#'
                                className='card-title fw-bolder text-dark-75 text-hover-primary fs-4'
                              >
                                {mls(emailTemplateElem?.title)}
                              </a>
                              <p
                                className=' fw-bold fs-5 m-0 text-muted'
                                dangerouslySetInnerHTML={{
                                  __html: emailTemplateElem?.description ?? '',
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EmailTemplateWrapper;
