// export const checkCustomizeERPPresence = (inputString: string) => {
//   const functionSignatureRegex =
//     /function\s+customizeERP\s*\(\s*{\s*token\s*,\s*functionID\s*,\s*appID\s*,\s*data\s*}\s*\)/;
//   return functionSignatureRegex.test(inputString);

import { buttonTypeObject } from '../data/appComponentData';

export const checkCustomizeERPPresence = ({
  inputString,
  functionType,
}: {
  inputString: any;
  functionType: any;
}) => {
  if (functionType === buttonTypeObject.formValidation.key) {
    // const formValidationFunctionSignatureRegex =
    //   /^\s*function\s+customizeERP\s*\(\s*{\s*token\s*,\s*functionID\s*,\s*appID\s*,\s*data\s*}\s*\)/gm;
    const formValidationFunctionSignatureRegex =
      /^\s*function\s+customizeERP\s*\(\s*{\s*token\s*,\s*functionID\s*,\s*appID\s*,\s*data\s*}\s*,\s*{\s*readOp\s*}\s*\)/gm;
    return checkTopLevelCustomizeERPPresence({
      inputString,
      functionSignatureRegex: formValidationFunctionSignatureRegex,
    });
  }

  // const crudFunctionSignatureRegex =
  //   /^\s*function\s+customizeERP\s*\(\s*{\s*token\s*,\s*functionID\s*,\s*appID\s*}\s*,\s*supistaCRUDOperation\s*\)/gm;
  const crudFunctionSignatureRegex =
    /^\s*function\s+customizeERP\s*\(\s*{\s*token\s*,\s*functionID\s*,\s*appID\s*,\s*data\s*}\s*,\s*{\s*createOp\s*,\s*readOp\s*,\s*updateOp\s*,\s*removeOp\s*}\s*\)/gm;

  return checkTopLevelCustomizeERPPresence({
    inputString,
    functionSignatureRegex: crudFunctionSignatureRegex,
  });
};
const checkTopLevelCustomizeERPPresence = ({
  inputString,
  functionSignatureRegex,
}: {
  inputString: any;
  functionSignatureRegex: any;
}) => {
  // Find all matches for the function signature
  const matches = [...inputString.matchAll(functionSignatureRegex)];

  // Check if the function is at the top level by counting opening and closing braces before the match
  for (const match of matches) {
    // Get the index of the match
    const matchIndex = match.index;

    // Extract substring before the function match
    const substringBeforeMatch = inputString.substring(0, matchIndex);

    // Count the number of opening '{' and closing '}' curly braces before the match
    const openingBracesCount = (substringBeforeMatch.match(/{/g) || []).length;
    const closingBracesCount = (substringBeforeMatch.match(/}/g) || []).length;

    // If the number of opening braces equals the number of closing braces, it's top-level
    if (openingBracesCount === closingBracesCount) {
      return true; // The function is at the top level
    }
  }

  return false; // No top-level customizeERP function found
};
