const sample = {
  name: 'Product',
  columnName: '__d3__id__product',
  dataType: 'manyToMany', // manyToOne, oneToMany, oneToOne
  // conectedTableID: 'product',
  conectedComponentID: 'product',
};
// const specialDataType = ['hasOne', 'hasMany', 'belongsToMany', 'belongsTo'];
const specialDataType = ['hasOne', 'hasMany', 'belongsToMany'];

const getInnerData = ({ tableId, appDatatable }: { tableId: string; appDatatable: any }) => {
  let finalTabeData: { [key: string]: any } = {};
  let finalConectedTableId: any[] = [];
  let belongsToTableId: any[] = [];
  const tableData = appDatatable[tableId];
  if (tableData) {
    finalTabeData = tableData;
    if (tableData?.relations) {
      Object.values(tableData?.relations ?? {}).forEach((tableElem: any) => {
        const isSpecialDataType = specialDataType.some((elem) => elem === tableElem?.type);
        if (isSpecialDataType) {
          const tableData = appDatatable?.[tableElem?.target];
          if (tableData) {
            finalConectedTableId = [
              ...finalConectedTableId,
              {
                tableId: tableElem?.target,
                relationType: tableElem?.type,
                screenName: `${tableElem?.target}`,
                relationID: tableElem?.as,
                // screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
        if ('belongsTo' === tableElem?.type) {
          const belongsToTableData = appDatatable?.[tableElem?.target];
          if (belongsToTableData) {
            belongsToTableId = [
              ...belongsToTableId,
              {
                tableId: tableElem?.target,
                relationType: tableElem?.type,
                relationID: tableElem?.as,
                screenName: `${tableElem?.target}`,
                // screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
      });
    }
  }

  return { finalTabeData, finalConectedTableId, belongsToTableId };
};
export const getConnectedTables = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: any;
}) => {
  let connectedTableList: { [key: string]: any } = {};

  const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
    tableId,
    appDatatable,
  });
  if (finalTabeData?.tableID) {
    // level 1
    connectedTableList = {
      ...connectedTableList,
      [finalTabeData?.tableID]: {
        ...finalTabeData,
        relationType: 'MAIN',
        screenName: finalTabeData?.name,
        nameWithRelationType: `${finalTabeData?.name} ( MAIN )`,
      },
    };
    (belongsToTableId ?? []).forEach(({ tableId, relationType, relationID, screenName }: any) => {
      if (!connectedTableList[tableId]) {
        connectedTableList = {
          ...connectedTableList,
          [tableId]: {
            ...(appDatatable[tableId] ?? {}),
            relationType,
            relationID,
            screenName,
            nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
          },
        };
      }
    });
    (finalConectedTableId ?? []).forEach(
      ({ tableId, relationType, relationID, screenName }: any) => {
        const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
          tableId,
          appDatatable,
        });
        if (finalTabeData?.tableID) {
          // level 2
          if (!connectedTableList[finalTabeData?.tableID]) {
            connectedTableList = {
              ...connectedTableList,
              [finalTabeData?.tableID]: {
                ...finalTabeData,
                relationType,
                relationID,
                screenName,
                nameWithRelationType: `${
                  appDatatable[finalTabeData?.tableID]?.name
                } ( ${relationType} )`,
              },
            };
          }
          (belongsToTableId ?? []).forEach(
            ({ tableId, relationType, relationID, screenName }: any) => {
              if (!connectedTableList[tableId]) {
                connectedTableList = {
                  ...connectedTableList,
                  [tableId]: {
                    ...(appDatatable[tableId] ?? {}),
                    relationType,
                    relationID,
                    screenName,
                    nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                  },
                };
              }
            }
          );
          (finalConectedTableId ?? []).forEach(
            ({ tableId, relationType, relationID, screenName }: any) => {
              const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
                tableId,
                appDatatable,
              });

              if (finalTabeData?.tableID) {
                // level 3
                if (!connectedTableList[finalTabeData?.tableID]) {
                  connectedTableList = {
                    ...connectedTableList,
                    [finalTabeData?.tableID]: {
                      ...finalTabeData,
                      relationType,
                      relationID,
                      screenName,
                      nameWithRelationType: `${
                        appDatatable[finalTabeData?.tableID]?.name
                      } ( ${relationType} )`,
                    },
                  };
                }
              }
              (belongsToTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
                if (!connectedTableList[tableId]) {
                  connectedTableList = {
                    ...connectedTableList,
                    [tableId]: {
                      ...(appDatatable[tableId] ?? {}),
                      relationType,
                      relationID,
                      screenName,
                      nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                    },
                  };
                }
              });
              (finalConectedTableId ?? []).forEach(
                ({ tableId, relationType, relationID, screenName }: any) => {
                  const { finalTabeData, belongsToTableId } = getInnerData({
                    tableId,
                    appDatatable,
                  });
                  if (finalTabeData?.tableID) {
                    // level 4
                    if (!connectedTableList[finalTabeData?.tableID]) {
                      connectedTableList = {
                        ...connectedTableList,
                        [finalTabeData?.tableID]: {
                          ...finalTabeData,
                          relationType,
                          relationID,
                          screenName,
                          nameWithRelationType: `${
                            appDatatable[finalTabeData?.tableID]?.name
                          } ( ${relationType} )`,
                        },
                      };
                    }
                    (belongsToTableId ?? []).forEach(
                      ({ tableId, relationType, relationID, screenName }: any) => {
                        if (!connectedTableList[tableId]) {
                          connectedTableList = {
                            ...connectedTableList,
                            [tableId]: {
                              ...(appDatatable[tableId] ?? {}),
                              relationType,
                              relationID,
                              screenName,
                              nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                            },
                          };
                        }
                      }
                    );
                  }
                }
              );
            }
          );
        }
      }
    );
  }

  return connectedTableList;
};

export const tableDataToRelatedTableData = ({ tableData }: { tableData: any }) => {
  let defaultView: any[] = [];
  (tableData?.columnSchema ?? []).forEach((elem: any) => {
    const isStartWithD3 = (elem?.columnName ?? '').startsWith('__d3__');
    if (!isStartWithD3) {
      defaultView = [
        ...defaultView,
        {
          columnName: elem?.columnName ?? '',
          filters: {},
          name: elem?.name ?? '',
          description: '',
        },
      ];
    }
  });
  if (tableData?.relationType === 'belongsTo') {
    const id = {
      columnName: 'id',
      filters: {},
      name: 'id',
      description: '',
    };
    return {
      defaultView: [id],
      screenName: tableData?.screenName,
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      relationID: tableData?.relationID,
      description: '',
    };
  } else if (tableData?.relationType === 'MAIN') {
    return {
      defaultView: defaultView ?? [],
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      // relationID: tableData?.relationID,
    };
  }
  return {
    defaultView: defaultView ?? [],
    relationType: tableData?.relationType,
    tableID: tableData?.tableID,
    screenName: tableData?.screenName,
    relationID: tableData?.relationID,
    description: '',
  };
};
export const getTableListData = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: { [key: string]: any };
}) => {
  const connectedTablesData = getConnectedTables({
    tableId: tableId,
    appDatatable,
  });
  let relatedTableList: { [key: string]: any }[] = [];
  Object.keys(connectedTablesData ?? {}).forEach((tableID) => {
    relatedTableList = [
      ...relatedTableList,
      tableDataToRelatedTableData({ tableData: connectedTablesData[tableID] }),
    ];
  });
  return relatedTableList;
};
export const getAppComponentColumnSchema = ({
  connectedTableList,
  connectedTablesData,
}: {
  connectedTableList: string[];
  connectedTablesData: any;
}) => {
  let allColumnSchema: any[] = [];
  (connectedTableList ?? []).forEach((connectedTablekey) => {
    const connectedTableElem = connectedTablesData?.[connectedTablekey] ?? {};
    let relationData: { [key: string]: any } = { relationType: connectedTableElem?.relationType };
    if (connectedTableElem?.relationType !== 'MAIN') {
      relationData = {
        ...relationData,
        relationID: connectedTableElem?.relationID,
      };
    }
    const newColumnSchema = (connectedTableElem?.columnSchema ?? [])?.map(
      (columnSchemaElem: any) => ({
        ...columnSchemaElem,
        tableName: connectedTablekey,
        ...relationData,
      })
    );
    allColumnSchema = [...allColumnSchema, ...newColumnSchema];
  });
  return allColumnSchema;
};
