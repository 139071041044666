export const checkForNumber = (input: String | Number) => {
  const newInput = Number(input);
  if (typeof input === 'string') {
    if (input.trim() === '') {
      return { data: input, isNum: false };
    }
  }
  if (isNaN(newInput)) {
    return { data: input, isNum: false };
  }
  return { data: newInput, isNum: true };
};
