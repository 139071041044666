import './slideSwitch.scss';
const SlideSwitch = ({ isChecked = false, setIsChecked }) => {
  const toggleChecked = (e) => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className='SlideSwitchToggle'>
        <span>
          <input
            type='checkbox'
            id='toggleInput'
            checked={isChecked}
            onChange={(e) => toggleChecked(e)}
          />
          <button
            className='SlideSwitchSlider'
            type='button'
            onClick={(e) => toggleChecked(e)}
          ></button>
        </span>
      </div>
    </>
  );
};

export default SlideSwitch;
