const en = {
  'CSV Import': 'CSV Import',
  'CSV Export': 'CSV Export',
  'Table Options': 'Table Options',
  'Import your existing CSV data to this table.': 'Import your existing CSV data to this table.',
  'Export the filtered CSV data of this table.': 'Export the filtered CSV data of this table.',
  'Table Setting': 'Table Setting',
  'Update the columns of this table.': 'Update the columns of this table.',
  'You don\'t have sufficient rights to perform this action!': 'You don\'t have sufficient rights to perform this action!',
  'Rows deleted successfully': 'Rows deleted successfully',
  'There was a problem while deleting, please try again later.': 'There was a problem while deleting, please try again later.',
  'Row was already deleted.': 'Row was already deleted.',
  'Rows created successfully': 'Rows created successfully',
  'There was a problem while creating row, please try again later!': 'There was a problem while creating row, please try again later!',
  'There was a problem while fetching App Schema, please try again later!': 'There was a problem while fetching App Schema, please try again later!',
  'Analytics': 'Analytics',
  'Create an Account': 'Create an Account',
  'Sign In to Supista': 'Sign In to Supista',
  'New Here?': 'New Here?',
  'Clear Filters': 'Clear Filters',
  'Page Size': 'Page Size',
  'No rows to show': 'No rows to show',
  'Sign Out': 'Sign Out',
  'Language': 'Language',
  'Please wait while your rows are loading': 'Please wait while your rows are loading',
  'Deleting Rows': 'Deleting Rows',
  'Are you sure to delete the selected items?': 'Are you sure to delete the selected items?',
  // version 2
  'all': 'all', 
  'today': 'today', 
  'this week' : 'this week',
  'this month': 'this month',
  'this year' : 'this year',
  'custom date': 'custom date',
  'Filter Date': 'Filter Date',
  'Select Custom Date': 'Select Custom Date',
  'to': 'to',
  'CSV File to Upload:': 'CSV File to Upload:',
  'Drag & drop some files here, or click to select files': 'Drag & drop some files here, or click to select files',
  'Row updated successfully': 'Row updated successfully',
  'There was a problem while updating row , please try again later!': 'There was a problem while updating row, please try again later!',
  Edit: 'Edit',
  // version 3
  ForgotPassword: 'Forgot Password?',
  'Page Not Found': 'Page Not Found',
  'The page you looked not found!': 'The page you looked not found!',
  'Create': 'Create',
  'Update': 'Update',
  'Click to edit': 'Click to edit',
};

export default en;
