import React, { useEffect, useRef, useState } from 'react';
import './dropDown.scss';
import arrowDropDownIcon from '../../assets/arrowDropDownIcon.svg';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { BiFilterAlt } from 'react-icons/bi';

const NestedDropDownObject = ({
  object,
  dropDownValue,
  setdropDownValue,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  mainKey = 'key',
  AddWithName = '',
  swithToIcon = false,
}) => {
  const isObject = typeof dropDownValue === 'object' ? true : false;
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(object);
  const [searchInput, setSearchInput] = useState(filteredData[dropDownValue]);
  const wrapperRef = useRef(null);

  const handleDropDown = () => {
    setisDropDownOpen((currentData) => !currentData);
  };
  const stopPropgation = (e) => {
    e.stopPropagation();
    setisDropDownOpen(true);
  };
  const handleSelection = (elem) => {
    // setSearchInput(filteredData[elem]);
    setdropDownValue(elem);
    handleDropDown();
  };

  const handleFilter = (e) => {
    let filtered = {};
    Object.keys(object).forEach((str) => {
      let keyword = e?.target?.value.toLowerCase();
      let currenObject = object[str].toLowerCase();
      if (currenObject.includes(keyword)) {
        filtered[str] = object[str];
      }
    });
    setFilteredData(filtered);
    // setSearchInput(e.target.value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // setSearchInput(object[dropDownValue[mainKey]]);
        setTimeout(() => {
          setFilteredData(object);
        }, 100);
        handleDropDown();
      }
    }
    if (isDropDownOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isDropDownOpen]);

  const isSelected = (element, dropdown) => {
    if (isObject) {
      return element === dropdown?.[mainKey] ? 'dropDownItems selected' : 'dropDownItems';
    }
    return element === dropdown ? 'dropDownItems selected' : 'dropDownItems';
  };

  // useUpdateEffect(() => {
  //   setSearchInput(isObject ? dropDownValue?.[nameKey] : dropDownValue);
  // }, [changeToReset]);
  useUpdateEffect(() => {
    setFilteredData(object);
  }, [object]);
  return (
    <div className={`CustomDropdown ${className}`} ref={wrapperRef}>
      {swithToIcon ? (
        <div className='filterIcon' onClick={() => handleDropDown()}>
          <BiFilterAlt />
        </div>
      ) : (
        <div className='mainTitle' onClick={() => handleDropDown()}>
          <>
            {filteredData[dropDownValue]?.[nameKey]}
            {AddWithName}
          </>
          <img className={isDropDownOpen ? ' open' : ''} src={arrowDropDownIcon} alt='' />
        </div>
      )}

      {/* <div className="fullDiv">s</div> */}
      <div className={isDropDownOpen ? 'dropDownArray open' : 'dropDownArray'}>
        {React.Children.toArray(
          Object.keys(filteredData).map((key) => {
            return (
              <div
                className={isSelected(key, dropDownValue)}
                onClick={() => handleSelection(key)}
                title={filteredData[key]?.[nameKey]}
              >
                {filteredData[key]?.[nameKey]}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NestedDropDownObject;
