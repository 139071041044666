import { erpValidationTypes } from 'lib/erp-data-table/components/data/erpData';
import styles from '../columnSettings.module.scss';
import clsx from 'clsx';
import React from 'react';
import { mls } from 'lib/multilanguagesupport';

const ValidationFields = ({
  validationData,
  validationOptions,
  handleChangeValidation,
  handleDelete = () => {},
  isDeleteOption = false,
}: {
  validationData: any;
  validationOptions: any;
  handleChangeValidation: Function;
  handleDelete?: Function;
  isDeleteOption?: boolean;
}) => {
  return (
    <div className='row mt-1'>
      <div className='col-6'>
        <div className='d-flex align-items-center'>
          {isDeleteOption ? (
            <i
              onClick={() => handleDelete()}
              className='fas fa-trash-alt text-danger'
              style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
            ></i>
          ) : null}
          <select
            name='type'
            onChange={(e) => handleChangeValidation(e, 'type')}
            className='form-select form-control form-control-lg'
          >
            <option value='' selected={validationData.type === '' ? true : false} hidden>
              Select...
            </option>
            {React.Children.toArray(
              Object.keys(validationOptions).map((validationKey: string) => {
                const validationElem: any =
                  validationOptions[validationKey as keyof typeof validationOptions];
                return (
                  <option
                    value={validationElem.key}
                    selected={validationData.type === validationElem.key ? true : false}
                  >
                    {validationElem.name}
                  </option>
                );
              })
            )}
          </select>
        </div>
      </div>
      <div className='col-6'>
        <input
          placeholder='Error Message'
          className={clsx('form-control form-control-lg', {
            'is-invalid': validationData.error,
          })}
          type='text'
          name='error'
          autoComplete='off'
          onChange={(e) => handleChangeValidation(e, 'message')}
          value={validationData.message}
        />
      </div>
      {validationData.error && (
        <div className='col-12 text-danger mt-1'>{validationData.error}</div>
      )}
      {validationData.type === erpValidationTypes.isLength.key && (
        // <div className={`row mx-3 ${styles.valueFieldInnerContainer}`}>
        <div className={`${styles.valueFieldOuterContainer}`}>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Min Value')}</label>
            <input
              placeholder='Min Value'
              className={clsx('form-control form-control-lg ')}
              type='number'
              name='minValue'
              autoComplete='off'
              value={validationData.minValue ?? 0}
              // style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
              onChange={(e) => handleChangeValidation(e, 'minValue')}
            />
          </div>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Max Value')}</label>
            <input
              placeholder='Max Value'
              className={clsx('form-control form-control-lg ')}
              type='number'
              name='maxValue'
              autoComplete='off'
              value={validationData.maxValue ?? ''}
              onChange={(e) => handleChangeValidation(e, 'maxValue')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ValidationFields;
