import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './kanbanSettings.module.scss';
interface boardOrderPorps {
  boardOrder: any[];
  setBoardOrder: Function;
}

const BoardOrder = ({ boardOrder, setBoardOrder }: boardOrderPorps) => {
  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const newColumnOrder = Array.from(boardOrder);
    const [sourceItem]: any[] = newColumnOrder.splice(source.index, 1);
    newColumnOrder.splice(destination.index, 0, sourceItem);
    setBoardOrder(newColumnOrder);
  };
  if (boardOrder.length <= 0) {
    return null;
  }
  return (
    <div className={styles.boardOrder}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='all-columns'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.boardOrderOuterContainer}
            >
              {boardOrder.map((boardElem, index) => {
                const boardLabel = boardElem?.label ?? '';
                const boardValue = boardElem?.value ?? '';

                return (
                  <Draggable draggableId={boardValue} index={index} key={boardValue}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        className={styles.boardOrderElem}
                      >
                        {boardLabel}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BoardOrder;
