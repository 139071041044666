import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import React, { useMemo, useState } from 'react';
import { getAppComponentColumnSchema, getConnectedTables } from '../../PDFTemplates/helperFuntion';
import RoleComponentFilter from 'lib/appComponent/componentSettings/RoleComponentFilter/RoleComponentFilter';

interface kanbanFilterProps {
  component: any;
  filterData: any;
  setFilterData: Function;
  setFinalFilter: Function;
}
const KanbanFilter = ({
  component,
  filterData,
  setFilterData,
  setFinalFilter,
}: kanbanFilterProps) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  const connectedTablesData = useMemo(() => {
    const appDatatable = appSchema?.appDatatable ?? {};

    const connectedTablesData = getConnectedTables({
      tableId: component?.table ?? '',
      appDatatable,
    });
    return connectedTablesData;
  }, [component?.table, appSchema?.appDatatable]);
  const columnSchema = useMemo(() => {
    return getAppComponentColumnSchema({
      connectedTableList: component?.connectedTableList ?? [],
      connectedTablesData: connectedTablesData,
    });
  }, [component?.connectedTableList, connectedTablesData]);
  const handleFilterPopupStatus = () => {
    setFinalFilter(filterData?.filters);
    setFilterData({ ...filterData, popupStatus: !filterData.popupStatus, specialCase: false });
  };
  const handleFilters = (action: any) => {
    setFilterData({ ...filterData, filters: action });
  };

  return (
    <div>
      {filterData.popupStatus ? (
        <RoleComponentFilter
          setChartFilter={handleFilters}
          chartFilter={filterData?.filters ?? {}}
          allTableData={columnSchema}
          appID={''}
          sheetID={''}
          isJoinedTable={false}
          specialCase={filterData.specialCase}
          isPopUp={filterData.popupStatus}
          setIsPopUp={handleFilterPopupStatus}
        />
      ) : null}
    </div>
  );
};

export default KanbanFilter;
