import { erpValidationTypes } from 'lib/erp-data-table/components/data/erpData';

export const getValidationDataByType = ({
  validationType,
  validationData = {
    type: validationType,
    message:
      erpValidationTypes[validationType as keyof typeof erpValidationTypes]?.defaultMessage ?? '',
  },
}: {
  validationType: string;
  validationData?: any;
}) => {
  let newValidation = validationData;
  if (newValidation.type === erpValidationTypes.isLength.key) {
    newValidation = {
      ...newValidation,
      minValue: 0,
      maxValue: 1,
    };
  }
  return newValidation;
};
export const validationErrorCheck = ({
  field,
  value,
  validation,
}: {
  field: string;
  value: any;
  validation: any;
}) => {
  let error = '';
  let isError = false;
  const currentValidation = { ...validation, [field]: value };
  if (field === 'type' && !value) {
    error = 'Validation type is required';
  } else if (field === 'message' && !`${value}`.trim()) {
    error = 'Error message is required';
  }
  if (error) {
    currentValidation.error = error;
    isError = true;
  } else {
    delete currentValidation.error;
    isError = false;
  }
  return { validtionData: currentValidation, isError: isError };
};
export const checkAllErrorValidtion = ({ validation }: { validation: any }) => {
  const validationData = getValidationDataByType({ validationType: validation.type });
  let isError = false;
  let udpatedValidation = validation;
  Object.keys(validationData ?? {}).some((validationKey) => {
    const validationElem = validation[validationKey];
    const validationErrorData = validationErrorCheck({
      field: validationKey,
      value: validationElem,
      validation,
    });
    isError = validationErrorData.isError;
    if (validationErrorData.isError) {
      udpatedValidation = validationErrorData.validtionData;
    }
    return validationErrorData.isError;
  });
  return { udpatedValidation, isError };
};

export const updatedValidaitonOption = ({
  validationOptions,
  localValidationData,
}: {
  validationOptions: any;
  localValidationData: any;
}) => {
  let newTypeObject: any = {};
  Object.keys(validationOptions ?? {}).forEach((typeKey: string) => {
    const typeData = validationOptions[typeKey];
    const isValidationTypeNotPresent = !localValidationData.some((v: any) => {
      return v.type === typeData?.key;
    });
    if (isValidationTypeNotPresent) {
      newTypeObject = { ...newTypeObject, [typeData.key]: typeData };
    }
  });
  return newTypeObject;
};
