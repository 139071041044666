import React, { useMemo, useState } from 'react';
import MainConnectedTableSelection from '../MainConnectedTableSelection';
import { Accordion } from 'react-bootstrap';
import styles from './additionalSettings.module.scss';
import ColumnDragableContainer from './ColumnDragableContainer';
import AppComponentFilter from '../../AppComponentFilter/AppComponentFilter';
import { getAppComponentColumnSchema } from '../PDFTemplates/helperFuntion';
import { mls } from 'lib/multilanguagesupport';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';

interface AdditionalSettingsProps {
  componentSchema: any;
  connectedTablesData: any;
  appDatatable: any;
  appSchema: any;
  setRelatedTableList: Function;
}
const AdditionalSettings = ({
  componentSchema,
  connectedTablesData,
  appDatatable,
  setRelatedTableList,
  appSchema,
}: AdditionalSettingsProps) => {
  const [filterData, setFilterData] = useState({
    popupStatus: false,
    filters: {},
    tableID: '',
    columnName: '',
    relatedTableIndex: 0,
  });
  const handleFilters = (action: any) => {
    setFilterData({ ...filterData, filters: action });
  };
  const relatedTableList = componentSchema?.tableList ?? [];

  const updateRelatedTable = ({
    updatedTableData,
    relatedTableIndex,
  }: {
    updatedTableData: { [key: string]: any };
    relatedTableIndex: number;
  }) => {
    const reorderedTableListView = Array.from(relatedTableList);
    reorderedTableListView.splice(relatedTableIndex, 1);
    reorderedTableListView.splice(relatedTableIndex, 0, updatedTableData);
    setRelatedTableList(reorderedTableListView);
  };
  const handleFilterPopupStatus = () => {
    const filters = filterData?.filters;
    const relatedTableIndex = filterData?.relatedTableIndex;
    const columnName = filterData?.columnName;
    const relatedTable = relatedTableList[relatedTableIndex] ?? {};
    const newDefaultView = (relatedTable?.defaultView ?? []).map((columnNameElem: any) => {
      if (columnNameElem?.columnName === (columnName ?? '')) {
        return { ...columnNameElem, filters: filters };
      }
      return columnNameElem;
    });

    updateRelatedTable({
      updatedTableData: {
        ...relatedTable,
        defaultView: newDefaultView,
      },
      relatedTableIndex,
    });

    setFilterData({ ...filterData, popupStatus: !filterData.popupStatus });
  };
  const columnSchema = useMemo(() => {
    return getAppComponentColumnSchema({
      connectedTableList: componentSchema?.connectedTableList ?? [],
      connectedTablesData,
    });
  }, [componentSchema?.connectedTableList, connectedTablesData]);
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedRelatedTableList = Array.from(relatedTableList);
    const [movedRelatedTableList] = reorderedRelatedTableList.splice(result.source.index, 1);
    reorderedRelatedTableList.splice(result.destination.index, 0, movedRelatedTableList);
    setRelatedTableList(reorderedRelatedTableList);
  };
  return (
    <div className={styles.AdditionalSettings}>
      <div className={styles.mainTableContainer}>
        <div className={styles.mainTableTitle}>{mls('Main Table')}:</div>
        <div className={styles.selectColumnContainer}>
          <Accordion>
            <ColumnDragableContainer
              relatedTable={(relatedTableList ?? [])?.[0] ?? {}}
              relatedTableIndex={0}
              appDatatable={appDatatable}
              setRelatedTableList={setRelatedTableList}
              connectedTablesData={connectedTablesData}
              filterData={filterData}
              setFilterData={setFilterData}
              relatedTableList={relatedTableList}
            />
          </Accordion>
        </div>
      </div>
      <div className={styles.connectedTablesContainer}>
        <div className={styles.chooseConnectedTables}>
          <MainConnectedTableSelection
            connectedTablesData={connectedTablesData}
            componentSchema={componentSchema}
            relatedTableList={relatedTableList ?? []}
            setRelatedTableList={setRelatedTableList}
          />
          {filterData.popupStatus ? (
            <AppComponentFilter
              setChartFilter={handleFilters}
              chartFilter={filterData?.filters ?? {}}
              allTableData={columnSchema}
              appID={''}
              sheetID={''}
              isJoinedTable={false}
              isPopUp={filterData.popupStatus}
              setIsPopUp={handleFilterPopupStatus}
            />
          ) : null}
        </div>
        <div className={styles.selectColumnContainer}>
          <Accordion>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='droppable-1'>
                {(provided) => (
                  <div
                    className='accordion'
                    id='kt_accordion_1'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {React.Children.toArray(
                      (relatedTableList ?? []).map(
                        (relatedTable: { [key: string]: any }, relatedTableIndex: number) => {
                          if (componentSchema?.table === relatedTable?.tableID) {
                            return <></>;
                          }
                          return (
                            <Draggable
                              key={relatedTable?.tableID}
                              draggableId={relatedTable?.tableID}
                              index={relatedTableIndex}
                            >
                              {(providedDrag: any, _: any) => (
                                <div
                                  ref={providedDrag.innerRef}
                                  {...providedDrag.draggableProps}
                                  key={relatedTable?.tableID}
                                >
                                  <ColumnDragableContainer
                                    relatedTable={relatedTable}
                                    relatedTableIndex={relatedTableIndex}
                                    appDatatable={appDatatable}
                                    setRelatedTableList={setRelatedTableList}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    providedDrag={providedDrag}
                                    relatedTableList={relatedTableList}
                                    connectedTablesData={connectedTablesData}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AdditionalSettings;
