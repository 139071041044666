import { useDispatch, useSelector } from 'react-redux';
import reduxConstants from '../redux/reduxConstants';
import { create } from '../redux/stateSlice';
import { useState } from 'react';
// import { supistaApiGet } from 'app/network/apiConnections';
import { _supistaApiGet } from 'lib/server-connection/connections';

function useAuthenticate(appID) {
  const dispatch = useDispatch();

  useState(() => {
    _supistaApiGet(`validateUser/supista`)
      .then((response) => {
        if (response.isLoggedin) {
          dispatch(
            create({
              setPath: reduxConstants.config.LOGIN,
              value: { isLoggedin: response.isLoggedin, ...response },
            })
          );
        } else {
          dispatch(
            create({
              setPath: reduxConstants.config.LOGIN,
              value: { isLoggedin: false },
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.ANALYTICS_SCHEMA,
              value: {},
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.APP_SCHEMA,
              value: {},
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.USER_SCHEMA,
              value: {},
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.TABLE_SCHEMA,
              value: {},
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.ALL_TABLES,
              value: [],
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.JOIN_TABLES,
              value: [],
            })
          );
          dispatch(
            create({
              setPath: reduxConstants.config.FILTERS,
              value: {},
            })
          );
        }
      })
      .catch(() => {});
  });
  const authData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.LOGIN]
  );

  if (authData?.isLoggedin === true) {
    return true;
  }
  return false;
}

export default useAuthenticate;
