import { useDispatch } from 'react-redux';
import { create } from '../redux/stateSlice';

export default function useMessageHandling() {
  const dispatch = useDispatch();
  return (path, message, time, isActive) => {
    dispatch(
      create({
        setPath: path,
        value: {
          message: message,
          time: time,
          isActive: isActive,
        },
      })
    );
  };
}
