import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons'; // Importing the icon from react-bootstrap-icons
import { updateRelatedTableSchema } from '../modal/add-database/helper/updateRelatedTableSchema';
import Select from 'react-select';
import { relationTypeArrayOptions, relationTypesObject } from './data/erpData';
import { getIDColumnName } from './util/utilFunctions';
import { mls } from 'lib/multilanguagesupport';

const RelationsComponent = ({
  allDataTableData,
  dataTableSchema,
  setDataTableSchema,
  setRelatedTableList,
  setTableUpdates,
  tableUpdates,
}) => {
  const appID = dataTableSchema.appID;
  const [relations, setRelations] = useState(dataTableSchema?.relations || {});
  const [newRelations, setNewRelations] = useState({
    type: '',
    target: '',
    displayName: '',
  });
  const [errors, setErrors] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let displayName = newRelations.displayName;
    if (name === 'target') {
      displayName = `${value}`;
    }
    // else if (name === 'displayName') {
    //   displayName = value;
    // }
    setNewRelations({
      ...newRelations,
      [name]: value,
      displayName: displayName,
    });

    // Clear the error on input change
    setErrors('');
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorMessage = '';

    if (newRelations.type === '' && newRelations.target === '') {
      formIsValid = false;
      errorMessage = 'Relation Type and Relation Target are required.';
    } else if (newRelations.type === '') {
      formIsValid = false;
      formIsValid = false;
      errorMessage = 'Relation Type is required.';
    } else if (newRelations.target === '') {
      formIsValid = false;
      errorMessage = 'Target Table is required.';
    }

    // Check if the same combination of relation type and target table already exists
    const isDuplicate = Object.values(relations || []).some(
      (relation) => relation.type === newRelations.type && relation.target === newRelations.target
    );

    if (isDuplicate) {
      formIsValid = false;
      errorMessage = 'This combination of Relation Type and Target Table already exists.';
    }

    setErrors(errorMessage);
    return formIsValid;
  };

  const udpateBelongsTo = ({ relationData, action, relationElem, relationId }) => {
    let columnSchema = dataTableSchema?.columnSchema ?? [];

    if (relationElem.type === relationTypesObject.belongsTo.value) {
      if (action === 'add') {
        const columnName = getIDColumnName(relationElem.target);
        const isColumnPresent = columnSchema.some((col) => col?.columnName === columnName);
        if (!isColumnPresent) {
          const newColumnSchema = {
            name: relationElem.target ?? columnName,
            columnName: columnName,
            dataType: relationTypesObject.belongsTo.value,
            relationId: relationId,
          };
          columnSchema = [...columnSchema, newColumnSchema];
          const updatedAddColumn = [...(tableUpdates?.addColumns ?? []), newColumnSchema];
          setTableUpdates({
            ...(tableUpdates ?? {}),
            addColumns: updatedAddColumn,
          });
        }
      }
      if (action === 'remove') {
        const columnName = getIDColumnName(relationElem.target);
        let isAddColumnDelete = false;
        let isNormalColumnDelete = false;
        columnSchema = columnSchema.filter((col) => {
          const isNotFound = col?.columnName !== columnName;
          if (!isNotFound) {
            isNormalColumnDelete = true;
          }
          return isNotFound;
        });

        const updatedAddColumn = (tableUpdates?.addColumns ?? []).filter((col) => {
          const isNotFound = col?.columnName !== columnName;
          if (!isNotFound) {
            isAddColumnDelete = true;
          }
          return isNotFound;
        });

        let updatedDeleteColumns = tableUpdates?.deleteColumns ?? [];
        if (!isAddColumnDelete && isNormalColumnDelete) {
          updatedDeleteColumns = [...updatedDeleteColumns, relationElem];
        }
        setTableUpdates({
          ...(tableUpdates ?? {}),
          addColumns: updatedAddColumn,
          deleteColumns: updatedDeleteColumns,
        });
      }
    }
    const updatedDataTable = {
      ...dataTableSchema,
      columnSchema: columnSchema,
      relations: relationData,
    };
    setDataTableSchema(updatedDataTable);
  };
  const handleAddRelation = () => {
    if (!validateForm()) {
      return;
    }
    const uuid = uuidv4();
    let foreignKey;
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      foreignKey = getIDColumnName(newRelations.target);
      // foreignKey = 'id';
    } else if (newRelations.type === relationTypesObject.belongsTo.value) {
      foreignKey = getIDColumnName(dataTableSchema.target);
    } else if (newRelations.type === relationTypesObject.belongsToMany.value) {
      foreignKey = getIDColumnName(dataTableSchema.name);
    }
    let relationData;

    relationData = {
      ...newRelations,
      as: uuid,
      foreignKey,
      // foreignKey: newRelations.type === relationTypesObject.belongsTo.value ? 'id' : foreignKey,
    };

    if (newRelations.type === relationTypesObject.belongsToMany.value) {
      relationData.through = `${dataTableSchema.name}-${newRelations.target}-throughTable`;
      relationData.otherKey = getIDColumnName(newRelations.target);
    }
    let updatedRelationData = relations;
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          foreignKey: 'id',
        },
      };
    } else if (newRelations.type === relationTypesObject.belongsTo.value) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          foreignKey: getIDColumnName(newRelations.target),
        },
      };
    } else if (newRelations.type === relationTypesObject.belongsToMany.value) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          foreignKey: getIDColumnName(newRelations.target),
        },
      };
    }
    setRelations(updatedRelationData);

    // Update target table schema with the new relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      tableUpdates,
      setTableUpdates,
      setRelatedTableList,
      relationData: relationData,
      action: 'add',
      dataTableSchema,
    });
    udpateBelongsTo({
      relationData: updatedRelationData,
      action: 'add',
      // relationElem: newRelations,
      relationElem: newRelations,
      relationId: uuid,
    });
    setNewRelations({
      type: '',
      target: '',
      displayName: '',
    });
  };

  const handleUpdateRelation = (e, key) => {
    const { name, value } = e.target;
    const updatedRelation = { ...relations[key], [name]: value };
    if (updatedRelation.type === relationTypesObject.belongsToMany.value) {
      updatedRelation.through = `${dataTableSchema.name}-${updatedRelation.target}-throughTable`;
      updatedRelation.otherKey = `__d3id__${updatedRelation.target}Id`;
    }

    setRelations({
      ...relations,
      [key]: updatedRelation,
    });

    // Update target table schema with the updated relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      tableUpdates,
      setTableUpdates,
      setRelatedTableList,
      relationData: updatedRelation,
      action: 'update',
    });
  };

  const handleRemoveRelation = (key) => {
    const relationToRemove = relations[key];

    const updatedRelations = { ...relations };
    delete updatedRelations[key];
    setRelations(updatedRelations);

    // Update target table schema by removing the relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      tableUpdates,
      setTableUpdates,
      setRelatedTableList,
      relationData: relationToRemove,
      action: 'remove',
    });
    udpateBelongsTo({
      relationData: updatedRelations,
      action: 'remove',
      relationElem: relationToRemove,
      relationId: key,
    });
    // setDataTableSchema({ ...dataTableSchema, relations: updatedRelations });
  };

  // const options = Object.keys(allDataTableData)
  //   .filter((key) => key !== dataTableSchema.name && allDataTableData[key].tableType !== 'junction')
  //   .map((key) => ({
  //     value: allDataTableData[key].name,
  //     label: allDataTableData[key].name,
  //   }));
  const options = useMemo(() => {
    return Object.keys(allDataTableData)
      .filter(
        (key) => key !== dataTableSchema.name && allDataTableData[key].tableType !== 'junction'
      )
      .map((key) => ({
        value: allDataTableData[key].name,
        label: allDataTableData[key].name,
      }));
  }, [allDataTableData, dataTableSchema.name]);
  const finalTargetTableOptions = useMemo(
    () =>
      options.filter((option) => {
        const isFound = Object.values(relations ?? {}).some((relationElem) => {
          return relationElem.target === option.value;
        });
        return !isFound;
      }),
    [options, relations]
  );

  return (
    <>
      <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Relations')}</label>
      <div
        style={{
          border: '1px solid #e4e6ef',
          borderRadius: '.5rem',
          padding: '.5rem',
        }}
      >
        <Form className='mb-5 ms-3'>
          <Row className='align-items-end'>
            <Col md={5}>
              <Form.Group controlId='relationTarget'>
                <Form.Label>{mls('Target Table')}</Form.Label>
                <Select
                  name='target'
                  value={options.find((option) => option.value === newRelations.target) || ''}
                  onChange={(selectedOption) =>
                    handleInputChange({
                      target: { name: 'target', value: selectedOption?.value ?? '' },
                    })
                  }
                  options={finalTargetTableOptions}
                  isInvalid={!!errors.target}
                  menuPlacement='auto'
                  menuPosition='fixed'
                  menuPortalTarget={document.body}
                  placeholder='Select Target Table'
                  isSearchable
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {/* <Form.Control
                  as='select'
                  name='target'
                  value={newRelations.target}
                  onChange={handleInputChange}
                  isInvalid={!!errors.target}
                >
                  <option value=''>Select Target Table</option>
                  {Object.keys(allDataTableData).map((key) => (
                    <option key={key} value={allDataTableData[key].name}>
                      {allDataTableData[key].name}
                    </option>
                  ))}
                </Form.Control> */}
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group controlId='relationType'>
                <Form.Label>{mls('Type')}</Form.Label>
                <Select
                  name='type'
                  value={
                    relationTypeArrayOptions.find((option) => option.value === newRelations.type) ||
                    ''
                  }
                  onChange={(selectedOption) =>
                    handleInputChange({ target: { name: 'type', value: selectedOption.value } })
                  }
                  options={relationTypeArrayOptions}
                  placeholder='Select Type'
                  menuPlacement='auto'
                  isInvalid={!!errors.type}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Form.Group>
            </Col>
            {/* <Col md={3}>
              <Form.Group controlId='relationDisplayName'>
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  type='text'
                  name='displayName'
                  value={newRelations.displayName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col> */}
            <Col md={2}>
              <Button
                variant='primary'
                onClick={handleAddRelation}
                className='btn btn-sm btn-primary'
                style={{ marginBottom: '4px' }}
              >
                {mls('Add Relation')}
              </Button>
            </Col>
          </Row>
        </Form>
        {/* Error Section */}
        {errors.length > 0 && (
          <div className='alert alert-danger' role='alert'>
            {errors}
          </div>
        )}
        {Object.keys(relations).length > 0 && (
          <>
            <hr />
            <label className='form-label fs-6 fw-bolder text-dark'>
              {mls('Current Relations')}
            </label>
            <div className='m-3'>
              <Row
                className='fw-bold mb-2 p-2'
                style={{
                  borderBottom: 'solid 1px #DFE2EF',
                  // borderRadius: '6px',
                }}
              >
                <Col md={5}>{mls('Target Table')}</Col>
                <Col md={5}>{mls('Type')}</Col>
                <Col md={2}></Col>
              </Row>
              {Object.keys(relations).map((key) => {
                const relationsElem = relations[key];
                return (
                  <Form
                    key={key}
                    className='mb-3 p-2'
                    style={{
                      borderBottom: 'solid 1px #EFF0F7',
                      // borderRadius: '6px',
                    }}
                  >
                    <Row className='align-items-center'>
                      <Col md={5}>
                        {relationsElem?.target}
                        {/* <Form.Group controlId={`relationTarget-${key}`}>
                          <Select
                            name='target'
                            value={options.find((option) => option.value === relations[key].target)}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'target', value: selectedOption.value } },
                                key
                              )
                            }
                            options={options}
                            placeholder='Select Target Table'
                            menuPlacement='auto'
                            isSearchable
                          />
                        </Form.Group> */}
                      </Col>

                      <Col md={5}>
                        {relationsElem?.type}
                        {/* <Form.Group controlId={`relationType-${key}`}>
                          <Select
                            name='type'
                            value={relationTypeArrayOptions.find(
                              (option) => option.value === relations[key].type
                            )}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'type', value: selectedOption.value } },
                                key
                              )
                            }
                            options={relationTypeArrayOptions}
                            menuPlacement='auto'
                            placeholder='Select Type'
                            isInvalid={!!errors.type}
                          />
                        </Form.Group> */}
                      </Col>

                      <Col md={2} className='d-flex justify-content-center'>
                        <XCircleFill
                          color='grey'
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveRelation(key)}
                        />
                      </Col>
                    </Row>
                    {/* <Row className='align-items-center'>
                      <Col md={5}>
                        <Form.Group controlId={`relationTarget-${key}`}>
                          <Select
                            name='target'
                            value={options.find((option) => option.value === relations[key].target)}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'target', value: selectedOption.value } },
                                key
                              )
                            }
                            options={options}
                            placeholder='Select Target Table'
                            menuPlacement='auto'
                            isSearchable
                          />
                        </Form.Group>
                      </Col>

                      <Col md={5}>
                        {key}
                        {relations[key].type}
                        <Form.Group controlId={`relationType-${key}`}>
                          <Select
                            name='type'
                            value={relationTypeArrayOptions.find(
                              (option) => option.value === relations[key].type
                            )}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'type', value: selectedOption.value } },
                                key
                              )
                            }
                            options={relationTypeArrayOptions}
                            menuPlacement='auto'
                            placeholder='Select Type'
                            isInvalid={!!errors.type}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={2} className='d-flex justify-content-center'>
                        <XCircleFill
                          color='grey'
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveRelation(key)}
                        />
                      </Col>
                    </Row> */}
                  </Form>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RelationsComponent;
