import _ from 'lodash';

export const checkConnectionNameUnique = (appCredentials, name) => {
  if (!appCredentials) return true;

  if (!_.isEmpty(appCredentials)) {
    return !Object.entries(appCredentials).some((connection) => {
      return connection?.[1]?.name === name;
    });
  }

  return true;
};

export const removeSpacesFromKeys = (arrayOfObjects) => {
  const result = arrayOfObjects.map((obj) => {
    let newObj = {};
    for (let key in obj) {
      let newKey = key.replace(/\s+/g, '');
      newObj[newKey] = obj[key];
    }
    return newObj;
  });
  return result;
};
export const convertToTitleCase = (str = '') => {
  let spacedStr = str.replace(/_/g, ' ');
  spacedStr = spacedStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  let titleCaseStr = spacedStr.replace(/\b\w/g, (char) => char.toUpperCase());
  return titleCaseStr;
};
export const renameKeysInArrayOfObjects = (arrayOfObjects, oldKey, newKey) => {
  const newArrayOfObjects = arrayOfObjects.map((obj) => {
    const newObj = {
      ...obj,
      [newKey]: obj[oldKey],
    };
    delete newObj[oldKey];
    return newObj;
  });
  return newArrayOfObjects;
};

export const convertColumnDataType = (csvData, colName, targetType, originalCsvData) => {
  // Any column can be set as 'text' type
  if (targetType === 'text') {
    const updatedRowsData = originalCsvData.map((row) => {
      return { ...row, [colName]: String(row[colName]) };
    });
    return updatedRowsData;
  }

  if (targetType === 'number') {
    const updatedRowsData = [];
    for (var i = 0; i < csvData.length; i++) {
      const row = csvData[i];
      // If any value is found which cannot be converted to number, return false from here since conversion isn't possible
      if (isNaN(row[colName])) {
        return false;
      }
      const updatedRow = { ...row, [colName]: Number(row[colName]) };
      updatedRowsData.push(updatedRow);
    }
    return updatedRowsData;
  }

  if (targetType === 'boolean') {
    const updatedRowsData = [];
    const falseValues = ['false', 'f', 'no', 'n', '0', 0];
    for (let i = 0; i < csvData.length; i++) {
      const row = csvData[i];
      let updatedRow;
      if (falseValues.includes(row[colName].toLowerCase())) {
        updatedRow = { ...row, [colName]: false };
      } else {
        updatedRow = { ...row, [colName]: true };
      }
      updatedRowsData.push(updatedRow);
    }
    return updatedRowsData;
  }

  if (targetType === 'date' || targetType === 'date') {
    const res = !csvData.some((csvDataRow, index) => {
      const value = csvDataRow[colName];
      if (!value) return true;
      let dmy = value.split('/');
      if (!dmy) return true;
      if (dmy.length === 3) {
        if (checkNumber(dmy[0]) && checkNumber(dmy[1]) && checkNumber(dmy[2])) return false;
      } else {
        dmy = value.split('-');
        if (dmy.length === 3) {
          if (checkNumber(dmy[0]) && checkNumber(dmy[1]) && checkNumber(dmy[2])) return false;
        }
      }
      return true;
    });
    return res;
  }

  return true;
};

const checkNumber = (value) => {
  if (value === '') {
    return false;
  }
  var numberRegex = /^-?\d*\.?\d*$/;
  return numberRegex.test(value);
};

export const sortArrayOfObjects = (
  arrayOfObjects,
  sortBy = 'alphabetically',
  isSortAscending = true
) => {
  const sorted = _.sortBy(arrayOfObjects, (obj) => {
    if (sortBy === 'alphabetically') {
      return obj.name?.toLowerCase();
    }
    if (sortBy === 'createdAt') {
      return -obj.createdAt; // Using the negative value since larger value (later timestamp) should come first
    }
    if (sortBy === 'updatedAt') {
      return -obj.updatedAt; // Using the negative value since larger value (later timestamp) should come first
    }
  });
  return isSortAscending ? sorted : sorted.reverse();
};

export const charactersNotAllowedInTableAndColumnName = [
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '.',
  '/',
  '@',
  '^',
  '=',
  ';',
  ':',
  '{',
  '}',
  '[',
  ']',
];
export const updateDataByIndex = ({ index, arrayData, newData }) => {
  const copyArrayData = Array.from(arrayData);
  copyArrayData.splice(index, 1);
  copyArrayData.splice(index, 0, newData);
  return copyArrayData;
};
export const getIDColumnName = (tableID = '') => {
  return `__d3id__${tableID}Id`;
};

export const finalTableUpdate = ({ tableUpdates, originalDataTableSchema }) => {
  let updateColumns = [];
  (tableUpdates?.updateColumns).forEach((colElem) => {
    const tempColName = colElem?.columnName;
    const columnName =
      Object.keys(tableUpdates?.renameColumns ?? {}).find(
        (originalColName) => tableUpdates?.renameColumns[originalColName] === tempColName
      ) ?? tempColName;

    const columnSchema = (originalDataTableSchema?.columnSchema ?? []).find(
      (dataTableColumn) => dataTableColumn?.columnName === columnName
    );
    if (columnSchema) {
      if (columnSchema?.dataType !== colElem?.dataType) {
        updateColumns = [...updateColumns, colElem];
      }
    }
  });
  const udpatedTableUpdate = {
    ...tableUpdates,
    updateColumns: updateColumns,
  };
  return udpatedTableUpdate;
};
