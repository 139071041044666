import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import QueryContainerPermissionContainer from './QueryContainerPermissionContainer';

import { _supistaApiGet } from 'lib/server-connection/connections';

import { buttonTypeObject } from '../../data/appComponentData';
import SampleDataPopup from './SampleDataPopup';
import QueryContainer from './QueryContainer';
import { getDefaultInput } from '../../utils/componentSettingFunction';

interface AICustomizationEditorContainerProps {
  errors: any;
  componentRowList: any;
  componentSchema: any;
  setTitle: Function;
  activeButtonKey: string;
  setComponentSchema: Function;
  title: string;
  setErrors: Function;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  udpateCodeCustomization: Function;
  buttonType: string;
  codeContainerType: string;
  setCodeContainerType: Function;
  outputData: any;
  code: any;
  handleCodeChange: any;
  inputData: any;
  setInputData: Function;
  setOutputData: Function;
  appID: any;
  selectedId: any;
  setSelectedId: Function;
  setIsInputChanged: Function;
  selectedParentId: string;
  setSelectedParentId: Function;
  selectedTableData: { [key: string]: any };
  connectedTablesData: { [key: string]: any };
  isInputChanged: boolean;
  setCode: Function;
  setButtonType: Function;
  setActiveButtonKey: Function;
}
const AICustomizationEditorContainer = ({
  errors,
  componentRowList,
  componentSchema,
  setTitle,
  activeButtonKey,
  setComponentSchema,
  title,
  setErrors,
  selectedCode,
  selectedTable,
  udpateCodeCustomization,
  buttonType,
  codeContainerType,
  setCodeContainerType,
  outputData,
  setOutputData,
  code,
  handleCodeChange,
  inputData,
  setInputData,
  appID,
  selectedId,
  setSelectedId,
  selectedParentId,
  setSelectedParentId,
  selectedTableData,
  connectedTablesData,
  isInputChanged,
  setIsInputChanged,
  setCode,
  setButtonType,
  setActiveButtonKey,
}: AICustomizationEditorContainerProps) => {
  const [isSampleDataPopup, setIsSampleDataPopup] = useState(false);
  const [sampleData, setSampleData] = useState([]);

  const handleSelectedId = (e: any) => {
    setSelectedId(e.target.value);
    if (!isInputChanged) {
      setInputData(
        getDefaultInput({
          connectedTablesData: connectedTablesData,
          selectedTable,
          actionButtonKey: activeButtonKey,
          buttonType: buttonType,
          selectedId: e.target.value ?? null,
          parentId: selectedParentId ?? null,
        }) ?? '{}'
      );
    }
  };
  const handleSelectedParentId = (e: any) => {
    setSelectedParentId(e.target.value);
    if (!isInputChanged) {
      setInputData(
        getDefaultInput({
          connectedTablesData: connectedTablesData,
          selectedTable,
          actionButtonKey: activeButtonKey,
          buttonType: buttonType,
          selectedId: selectedId ?? null,
          parentId: e.target.value ?? null,
        }) ?? '{}'
      );
    }
  };

  useEffect(() => {
    const isRelatedTypeTable = selectedTableData?.relationType !== 'MAIN';
    if (!isRelatedTypeTable) {
      (async () => {
        let urlData = {};
        urlData = {
          __d3__filterdata: {
            where: {},
          },
        };
        if (selectedId || selectedId === '0' || selectedId === 0) {
          urlData = {
            __d3__filterdata: {
              where: { id: selectedId },
            },
          };
        }
        const filterUrl = `?data=${JSON.stringify(urlData)}`;
        const componentID = componentSchema?.componentID;
        const baseUrl = `app/${appID}/CRUD/${componentID}`;
        const finalUrl = `${baseUrl}${filterUrl}`;
        const componentRes = await _supistaApiGet(finalUrl);
        setSampleData(componentRes?.rows ?? []);
      })();
    }
  }, [
    selectedId,
    componentSchema?.componentID,
    appID,
    selectedTableData?.relationID,
    selectedTableData?.relationType,
  ]);

  useEffect(() => {
    const isRelatedTypeTable = selectedTableData?.relationType !== 'MAIN';

    if (isRelatedTypeTable) {
      (async () => {
        let urlData = {};
        urlData = {
          __d3__filterdata: {
            where: {},
          },
        };
        urlData = {
          ...urlData,
          __d3__filterdata: {
            where: {},
          },
          __d3__parentId: selectedParentId ?? null,
          __d3__relationId: selectedTableData?.relationID,
        };
        const filterUrl = `?data=${JSON.stringify(urlData)}`;
        const componentID = componentSchema?.componentID;
        const baseUrl = `app/${appID}/CRUD/${componentID}`;
        const finalUrl = `${baseUrl}${filterUrl}`;
        const componentRes = await _supistaApiGet(finalUrl);
        if (isRelatedTypeTable) {
          if (componentRes?.rows?.[0]) {
            handleSelectedId({ target: { value: componentRes?.rows?.[0]?.id ?? '' } });
          }
        }
        setSampleData(componentRes?.rows ?? []);
      })();
    }
  }, [
    selectedParentId,
    componentSchema?.componentID,
    appID,
    selectedTableData?.relationID,
    selectedTableData?.relationType,
  ]);
  const isRelatedTypeTable = selectedTableData?.relationType !== 'MAIN';

  return (
    <Col md={9} className={styles.editorContainer}>
      <div className={styles.queryContainerOuter}>
        <div
          className={styles.editorHeader}
          style={{ justifyContent: 'flex-start', marginBottom: 0, gap: '1rem' }}
        >
          <div style={{ width: 'auto', display: 'flex', textAlign: 'end' }}>
            {isRelatedTypeTable ? (
              <Form.Group as={Row} className='' style={{ marginLeft: 0, marginRight: 0 }}>
                <Form.Label column sm={3}>
                  {mls('Id')}:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    name='selectedParentId'
                    value={selectedParentId}
                    style={{ textAlign: 'end' }}
                    onChange={(e) => handleSelectedParentId(e)}
                    isInvalid={!!errors.componentID}
                  >
                    <option value='' disabled>
                      {mls('Select Id')}
                    </option>
                    {React.Children.toArray(
                      (componentRowList ?? []).map((componentRowData: any) => {
                        return (
                          <option value={componentRowData?.id ?? ''} disabled={false}>
                            {componentRowData?.id ?? ''}
                          </option>
                        );
                      })
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>{errors.componentID}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            ) : (
              <Form.Group as={Row} className='' style={{ marginLeft: 0, marginRight: 0 }}>
                <Form.Label column sm={3}>
                  {mls('Id')}:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    name='selectId'
                    value={selectedId}
                    style={{ textAlign: 'end' }}
                    onChange={(e) => handleSelectedId(e)}
                    isInvalid={!!errors.componentID}
                  >
                    <option value='' disabled>
                      {mls('Select Id')}
                    </option>
                    {React.Children.toArray(
                      (componentRowList ?? []).map((componentRowData: any) => {
                        return (
                          <option value={componentRowData?.id ?? ''} disabled={false}>
                            {componentRowData?.id ?? ''}
                          </option>
                        );
                      })
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>{errors.componentID}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            )}
          </div>
          <div
            style={{
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <div>
              <SampleDataPopup
                isSampleDataPopup={isSampleDataPopup}
                setIsSampleDataPopup={setIsSampleDataPopup}
                sampleData={sampleData}
              />
              {/* <b>{mls('Sample Data')}:</b> */}
              <button
                className='primaryButton secondaryButtonColor'
                onClick={() => setIsSampleDataPopup(true)}
              >
                {mls('Selected Row Data')}
              </button>
            </div>
          </div>
        </div>
        {buttonType === buttonTypeObject.actionButton.key ? (
          <>
            <div className={styles.editorHeader} style={{ marginBottom: 0 }}>
              <QueryContainerPermissionContainer
                componentSchema={componentSchema}
                title={title}
                setTitle={setTitle}
                activeButtonKey={activeButtonKey}
                setComponentSchema={setComponentSchema}
                errors={errors}
                setErrors={setErrors}
                selectedCode={selectedCode}
                selectedTable={selectedTable}
                udpateCodeCustomization={udpateCodeCustomization}
                connectedTablesData={connectedTablesData}
              />
            </div>
          </>
        ) : null}
        <QueryContainer
          componentSchema={componentSchema}
          activeButtonKey={activeButtonKey}
          title={title}
          selectedCode={selectedCode}
          selectedTable={selectedTable}
          udpateCodeCustomization={udpateCodeCustomization}
          buttonType={buttonType}
          codeContainerType={codeContainerType}
          setCodeContainerType={setCodeContainerType}
          outputData={outputData}
          code={code}
          handleCodeChange={handleCodeChange}
          inputData={inputData}
          setInputData={setInputData}
          setIsInputChanged={setIsInputChanged}
          appID={appID}
          setOutputData={setOutputData}
          sampleData={sampleData}
          setCode={setCode}
          setButtonType={setButtonType}
          setActiveButtonKey={setActiveButtonKey}
        />
      </div>
    </Col>
  );
};

export default AICustomizationEditorContainer;
