import EditTableSetting from 'lib/erp-data-table/components/EditTableSettingsPopup/EditTableSetting';
import React from 'react';

interface newDataTableSettingProps {
  dataTableSchema: any;
  setDataTableSchema: Function;
  tableUpdates: any;
  setTableUpdates: Function;
  relatedTableList: any;
  setRelatedTableList: Function;
  allDataTableData: any;
}
const NewDataTableSetting = ({
  dataTableSchema,
  setDataTableSchema,
  tableUpdates,
  setTableUpdates,
  allDataTableData,
  relatedTableList,
  setRelatedTableList,
}: newDataTableSettingProps) => {
  return (
    <div>
      <EditTableSetting
        allDataTableData={allDataTableData}
        relatedTableList={relatedTableList}
        setRelatedTableList={setRelatedTableList}
        tableUpdates={tableUpdates}
        setTableUpdates={setTableUpdates}
        dataTableSchema={dataTableSchema}
        setDataTableSchema={setDataTableSchema}
      />
    </div>
  );
};

export default NewDataTableSetting;
