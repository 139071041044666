import React, { useState } from 'react';
import styles from '../ComponentSettingWrapper.module.scss';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { mls } from 'lib/multilanguagesupport';
import { tableDataToRelatedTableData } from './PDFTemplates/helperFuntion';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { relationTypeObjects, specialDataTypeName } from '../data/appComponentData';
import { getIDColumnName } from '../utils/componentSettingFunction';

interface mainConnectedTableSelectionProps {
  connectedTablesData: { [key: string]: any };
  componentSchema: any;
  setRelatedTableList: Function;
  relatedTableList: { [key: string]: any }[];
}

const MainConnectedTableSelection = ({
  connectedTablesData,
  componentSchema,
  relatedTableList,
  setRelatedTableList,
}: mainConnectedTableSelectionProps) => {
  const [isSelectedTablePopup, setIsSelectedTablePopup] = useState(false);
  const handleIsSelectedTablePopup = () => {
    setIsSelectedTablePopup(!isSelectedTablePopup);
  };
  return (
    <div className={styles.tablesSelectionOuterContainer}>
      <div className={styles.tablesSelectionTitle}>{mls('Choose Connected Tables')}:</div>
      <button
        className='primaryButton secondaryButtonColor'
        onClick={() => handleIsSelectedTablePopup()}
      >
        {mls('Select Tables')}
      </button>
      {isSelectedTablePopup ? (
        <PopUpDivChild
          initialValue={isSelectedTablePopup}
          setPopUp={handleIsSelectedTablePopup}
          popupTitle={mls('Connected Tables')}
        >
          <div className={styles.tablesSelectionContainer}>
            {React.Children.toArray(
              Object.keys(connectedTablesData).map((connectedTableKey) => {
                const connectedTableFinalData =
                  connectedTablesData[connectedTableKey as keyof typeof connectedTablesData];
                const isSelected = (relatedTableList ?? []).some(
                  (connectedTableElem: any) => connectedTableElem?.tableID === connectedTableKey
                );
                const handleSelection = () => {
                  if (componentSchema?.table === connectedTableKey) {
                    return;
                  }

                  let udpatedConnectedTable = relatedTableList ?? [];

                  if (isSelected) {
                    udpatedConnectedTable = (relatedTableList ?? []).filter(
                      (connectedTableElem: any) => connectedTableElem?.tableID !== connectedTableKey
                    );
                    if (
                      connectedTableFinalData?.relationType === relationTypeObjects.belongsTo.key
                    ) {
                      const tableID = connectedTableFinalData?.tableID;
                      const columnName = getIDColumnName(tableID);
                      const mainTableIndex = (relatedTableList ?? []).findIndex(
                        (connectedTableElem: any) =>
                          connectedTableElem?.tableID === componentSchema?.table
                      );
                      if (mainTableIndex >= 0) {
                        const copyUdpatedConnectedTable = Array.from(udpatedConnectedTable ?? []);
                        const [mainTable] = copyUdpatedConnectedTable.splice(mainTableIndex, 1);
                        const mainTableDefalutView = (mainTable?.defaultView ?? [])?.filter(
                          (connectedTableElem: any) => connectedTableElem?.columnName !== columnName
                        );
                        const udpatedMainTable = {
                          ...mainTable,
                          defaultView: mainTableDefalutView,
                        };
                        copyUdpatedConnectedTable.splice(mainTableIndex, 0, udpatedMainTable);
                        udpatedConnectedTable = copyUdpatedConnectedTable;
                      }
                    }
                  } else {
                    udpatedConnectedTable = [
                      ...udpatedConnectedTable,
                      tableDataToRelatedTableData({
                        tableData: connectedTablesData[connectedTableKey],
                      }),
                    ];

                    if (
                      connectedTableFinalData?.relationType === relationTypeObjects.belongsTo.key
                    ) {
                      const tableID = connectedTableFinalData?.tableID;
                      const columnName = getIDColumnName(tableID);
                      const orignalMainTable = connectedTablesData[componentSchema?.table];
                      const belongsColumn = (orignalMainTable?.columnSchema ?? []).find(
                        (colElem: any) => colElem?.columnName === columnName
                      );
                      if (belongsColumn) {
                        const mainTableIndex = (relatedTableList ?? []).findIndex(
                          (connectedTableElem: any) =>
                            connectedTableElem?.tableID === componentSchema?.table
                        );
                        if (mainTableIndex >= 0) {
                          const copyUdpatedConnectedTable = Array.from(udpatedConnectedTable ?? []);
                          const [mainTable] = copyUdpatedConnectedTable.splice(mainTableIndex, 1);
                          const udpatedMainTable = {
                            ...mainTable,
                            defaultView: [...(mainTable?.defaultView ?? []), belongsColumn],
                          };
                          copyUdpatedConnectedTable.splice(mainTableIndex, 0, udpatedMainTable);
                          udpatedConnectedTable = copyUdpatedConnectedTable;
                        }
                      }
                    }
                  }

                  setRelatedTableList(udpatedConnectedTable);
                };
                if (componentSchema?.table === connectedTableKey) {
                  return <></>;
                }
                return (
                  <div
                    className={`${styles.columnSelectionDiv} ${
                      componentSchema?.table === connectedTableKey
                        ? styles.columnSelectionDivDisable
                        : ''
                    }`}
                    onClick={() => handleSelection()}
                  >
                    <SlideSwitch
                      isChecked={isSelected}
                      setIsChecked={handleSelection}
                      selectedDisable={componentSchema?.table === connectedTableKey}
                    />
                    {connectedTableFinalData?.name ?? connectedTableFinalData?.tableID}
                    &nbsp; ({' '}
                    {specialDataTypeName[
                      connectedTableFinalData?.relationType as keyof typeof specialDataTypeName
                    ]?.name ?? connectedTableFinalData?.relationType}{' '}
                    ) &nbsp;
                  </div>
                );
              })
            )}
          </div>
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default MainConnectedTableSelection;
