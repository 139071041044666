// src/components/Column.tsx
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Column as ColumnType, Task } from './kanbanTypes';
import TaskItem from './TaskItem';
import { mls } from 'lib/multilanguagesupport';
import styles from './kanban.module.scss';
interface ColumnProps {
  column: ColumnType;
  tasks: Task[];
  dragHandleProps: any;
  boardLabel: string;
  boardValue: string;
  component: any;
}

const Column: React.FC<ColumnProps> = ({
  column,
  tasks,
  dragHandleProps,
  boardLabel,
  boardValue,
  component,
}) => {
  const hanelAddItems = ({ boardValue }: { boardValue: string }) => {};
  return (
    <div className={styles.kanbanCardColumn}>
      <h2 {...dragHandleProps} style={{ textAlign: 'center' }}>
        {boardLabel}
      </h2>
      <Droppable droppableId={column?.id} type='task'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.map((task, index) => (
              <TaskItem key={task?.id} task={task} index={index} component={component} />
            ))}
            {provided.placeholder}
            <button
              type='button'
              className={`${styles.primaryButton} ${styles.secondaryButtonColor} ${styles.secondaryButtonColorWithBorder}`}
              onClick={() => hanelAddItems({ boardValue: boardValue })}
            >
              {mls('Add Items')}
            </button>
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default Column;
