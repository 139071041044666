export const defaultHtmlContent = `

<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>Email Template</title>
  <style type="text/css">
    body {
      font-family: 'Roboto Condensed', sans-serif;
      margin:0;
    }

    .border {
      background-color: white;
      width:  calc( 19cm - 10mm );
      margin-left: 1cm;
      margin-top: 1cm;
      margin-bottom: 1cm;
      border-color: teal;
      border-width: 1px;
      border-style: solid;
    }

    .parent-border {
      background-color: white;
      width: calc( 21cm - 10mm );
      height: auto;
      border-color: teal;
      border-width: 1px;
      border-style: solid;
    }

    .table-row-border>th {
      border-left-color: white;
      border-right-color: white;
      border-bottom-color: teal;
      border-top-color: teal;
      border-width: 2px;
      border-style: solid;
      margin: 0;
      padding: 0;
      width: 4cm;
      height: 1cm;
      text-align: center;
      color: teal;
    }

    td {
      height: 1cm;
      text-align: center;
    }

    .table-addresses>th {
      color: teal;
      text-align: center;
      width: 4cm;
      text-align: left;
    }

    table.invoice-table-address {
      margin-left: 1cm;
    }

    table.invoice-table-address td {
      font-size: 15px;
      text-align: left;
      height: 0.5cm;
    }

    .parent {
      position: relative;
    }

    .child {
      position: absolute;
    }

    .invoice-table {
      margin-left: 1cm;
      margin-right: 1cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-logo-type {
      height: 3cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-table-address {
      margin-top: 1cm;
      height: 4cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-table {
      margin-top: 1cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-total {
      margin-top: 1cm;
      /* border: teal 1px solid; */
      height: 1cm;
    }

    .parent-invoice-terms {
      margin-top: 4cm;
      /* border: teal 1px solid; */
      height: 5cm;
    }

    .invoice-type {
      font-size: 50px;
      font-weight: 700;
      color: teal;
      left: 1cm;
      bottom: 0cm;
    }

    .invoice-logo {
      right: 1cm;
      bottom: 0cm;
    }

    .invoice-total-text {
      font-size: 30px;
      font-weight: 700;
      color: teal;
      left: 1cm;
      bottom: 0cm;
    }

    .invoice-total {
      right: 1cm;
      bottom: 0cm;
      font-size: 30px;
      font-weight: 700;
    }

    .invoice-terms {
      left: 1cm;
      bottom: 0cm;
    }
  </style>
</head>

<body>
  <div class="parent-border">
    <div class="border">
      <div class="parent parent-invoice-logo-type">
        <span class="invoice-type child">
                    INVOICE 
                </span>
        {{!-- <img class="invoice-logo child" src="https://www.supista.com/assets/img/logo.png" alt="logo" width="100" height="100"> --}}
        <img class="invoice-logo child" src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/free-logo-space-template-design-39cac50098804820776e4efc76d5eb85_screen.jpg?ts=1586864091" alt="logo" width="100" height="100">
      </div>
      <br/>
      <br/>
        <h4>&nbsp;  &nbsp; &nbsp; &nbsp; Edit Html to Create Email Template</h4>
      <br/>
      <br/>
  </div>
</body>

</html>
`;
