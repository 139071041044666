import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styles from './ComponentSettingWrapper.module.scss';
import { FaTimes } from 'react-icons/fa';
import { mls } from 'lib/multilanguagesupport';
import Modal from '@mui/material/Modal';

const Header = ({ title, subtitle, handleClose, handleSave, handleDelete, isNew }) => {
  const [isDeleteConfirmation, setisDeleteConfirmation] = useState(false);
  return (
    <div
      className={`${styles.headerContent} d-flex justify-content-between align-items-center MainUpperHeader`}
    >
      <ConfirmDelete
        confirmDeleteModal={isDeleteConfirmation}
        setConfirmDeleteModal={setisDeleteConfirmation}
        handleDelete={handleDelete}
      />
      <div className={styles.headerTitles}>
        <div class='title'>{title}</div>
        <div class='subTitle'>{subtitle}</div>
      </div>
      <div className={styles.headerButtons}>
        {!isNew ? (
          // <Button variant='danger' size='sm' className='me-2' onClick={() => handleDelete()}>
          <Button
            variant='danger'
            size='sm'
            className='me-2'
            onClick={() => setisDeleteConfirmation(true)}
          >
            {mls('Delete')}
          </Button>
        ) : null}
        <Button variant='primary' size='sm' className='me-2' onClick={() => handleSave()}>
          {mls('Save')}
        </Button>
        <div className='closeButton' onClick={() => handleClose(false)}>
          <FaTimes />
        </div>
      </div>
    </div>
  );
};

export default Header;

const ConfirmDelete = ({ confirmDeleteModal, setConfirmDeleteModal, handleDelete }) => {
  return (
    <Modal open={confirmDeleteModal} onClose={() => setConfirmDeleteModal(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5'>
          <p className='p-5 mb-7 fs-4 fw-bold'>
            {mls('Are you sure you want to delete this Componenet')}?
          </p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={() => handleDelete()}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls('Delete Componenet')}
            </button>
            <button
              onClick={() => setConfirmDeleteModal(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
