import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './SearchInputDiv.scss';
import { mls } from 'lib/multilanguagesupport';
const SearchInputDiv = ({
  value,
  onChange,
  placeholder = mls('Search'),
  border = false,
  fullWidth = false,
}: {
  value?: string;
  onChange: Function;
  placeholder?: string;
  border?: boolean;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={`SearchInputDiv ${border ? 'SearchInputDivBorder' : ''} ${
        fullWidth ? 'SearchInputDivFullWidth' : ''
      }`}
    >
      <input
        type='text'
        value={value}
        placeholder={mls(placeholder)}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {value ? (
        <div className='searchIcon' style={{ cursor: 'pointer' }}>
          <FaTimes
            onClick={() => {
              onChange('');
            }}
          />
        </div>
      ) : (
        <img src='/static/media/searchIcon.c1aad085.svg' alt='' className='searchIcon' />
      )}
    </div>
  );
};

export default SearchInputDiv;
