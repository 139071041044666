export const getAppID=(pathName)=> {
  return pathName.split('/')?.[1];
}

export default getAppID;

export const getDefaultAppID=(userData)=> {
  return userData?.appsConnected?.[0];
}

