import React, { useEffect, useState } from 'react';

import xWhite from './assets/x.svg';

import {
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
} from 'date-fns';
// import format from 'date-fns/format';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { defaultDateObject, defaultDateKey } from './data/defaultFilterSchema';
import { checkForNumber } from './utils/dataFunctions';

const DatePicker = ({ dateData, setDateData, className = '' }) => {
  const mainKey = defaultDateKey.mainKey;
  const [isPopUp, setIsPopUp] = useState(false);
  const gapITCtoUTC = 19800000;

  const resetDateFormat = {
    startDate: null,
    endDate: new Date(null),
  };

  const [selectedDate, setSelectedDate] = useState(() => {
    if (dateData.period === defaultDateKey.CUSTOM) {
      return [
        {
          startDate: new Date(dateData?.startDate || resetDateFormat.startDate),
          endDate: new Date(dateData?.endDate || resetDateFormat.endDate),
        },
      ];
    }
    if (dateData.period === defaultDateKey.LASTXDAYS) {
      const updateDate = getDates(dateData.period, dateData.xDay);
      if (updateDate.isChange) {
        return [updateDate.data];
      }
    }
    const updateDate = getDates(dateData.period);
    if (updateDate.isChange) {
      return [updateDate.data];
    }
    return [resetDateFormat];
  });

  const handleSelect = (item) => {
    setSelectedDate([item.range1]);
    const newFormatDate = {
      startDate: new Date(item.range1.startDate.getTime() + gapITCtoUTC),
      endDate: new Date(item.range1.endDate.getTime() + gapITCtoUTC),
      period: defaultDateKey.CUSTOM,
    };
    delete dateData.xDay;
    const newElem = {
      ...dateData,
      ...newFormatDate,
    };
    setDateData(newElem);
  };

  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      setIsPopUp(false);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handlePopUp = () => {
    setIsPopUp(!isPopUp);
  };
  const handleDateData = (elem) => {
    if (elem === defaultDateKey.CUSTOM) {
      const newFormatDate = {
        startDate: dateData?.startDate || addDays(new Date(), -7),
        endDate: dateData?.endDate || new Date(),
      };
      const newElem = {
        [mainKey]: elem,
        ...newFormatDate,
      };
      setSelectedDate([newFormatDate]);
      setDateData(newElem);
      return;
    }
    if (elem === defaultDateKey.LASTXDAYS) {
      const newElem = {
        [mainKey]: elem,
        xDay: defaultDateKey.defalutLastDay,
      };
      const updateDate = getDates(elem, newElem.xDay);
      if (updateDate.isChange) {
        setSelectedDate([updateDate.data]);
      } else {
        setSelectedDate([resetDateFormat]);
      }
      setDateData(newElem);
      return;
    }
    const updateDate = getDates(elem);
    if (updateDate.isChange) {
      setSelectedDate([updateDate.data]);
    } else {
      setSelectedDate([resetDateFormat]);
    }
    setDateData({ [mainKey]: elem });
  };
  const handleXDaysChange = (e) => {
    const newNumber = checkForNumber(e.target.value);
    if (newNumber.data === '') {
      setDateData((curretData) => {
        const newElem = {
          ...curretData,
          xDay: newNumber.data,
        };
        return newElem;
      });
      return;
    }
    if (newNumber.data <= 0) {
      setDateData((curretData) => {
        const newElem = {
          ...curretData,
          xDay: 1,
        };
        return newElem;
      });
      return;
    }
    const updateDate = getDates(defaultDateKey.LASTXDAYS, newNumber.data);
    if (updateDate.isChange) {
      setSelectedDate([updateDate.data]);
    }
    setDateData((curretData) => {
      const newElem = {
        ...curretData,
        xDay: newNumber.data,
      };
      return newElem;
    });
  };

  useEffect(() => {
    // setCalendar(format(new Date(), 'MM/dd/yyyy'))
    document.addEventListener('keydown', hideOnEscape, true);
    // document.addEventListener("click",hideOnClickOutside, true)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', hideOnEscape);
    };
  }, []);

  return (
    <div className={`calendarWrap ${className}`}>
      <button className='primaryButton secondaryButtonColor' onClick={() => handlePopUp()}>
        {/* Filter By Date */}
        {defaultDateObject[dateData.period]}
        {CustomDate({ dateData })}
      </button>

      {isPopUp && (
        <div className='outerDatePickerDiv' onClick={() => handlePopUp()}>
          <div className='innerDatePickerDiv' onClick={(e) => stopPropagation(e)}>
            <div class='closeButton' onClick={() => handlePopUp()}>
              <img src={xWhite} alt='' class='arrowIcon' />
            </div>
            <div className='bottomDivPicker'>
              <div className='leftDivPickerOptionsContainer'>
                {React.Children.toArray(
                  Object.keys(defaultDateObject).map((dateKey) => {
                    return (
                      <>
                        <div
                          className={
                            dateData.period === dateKey
                              ? 'leftDivPickerOption active'
                              : 'leftDivPickerOption'
                          }
                          onClick={() => handleDateData(dateKey)}
                        >
                          {defaultDateObject[dateKey]}
                        </div>
                        {/* <hr /> */}
                      </>
                    );
                  })
                )}
                {defaultDateKey.LASTXDAYS === dateData[mainKey] && (
                  <div className='xDayDiv'>
                    <div className='titleDiv'>Days:</div>
                    <div className='inputDiv'>
                      <input
                        className='primaryInput'
                        type='number'
                        value={dateData?.xDay}
                        onChange={(e) => handleXDaysChange(e)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <DateRange
                onChange={(item) => handleSelect(item)}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={selectedDate}
                months={2}
                direction='horizontal'
                className='calendarElement verticalCalender'
              />
              {/* <DateRange
                onChange={(item) => handleSelect(item)}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={selectedDate}
                months={2}
                direction='vertical'
                className='calendarElement horizontalCalender'
              /> */}
              {/* <DateRange
                onChange={(item) => handleSelect(item)}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={selectedDate}
                months={1}
                direction='vertical'
                className='calendarElement singleCalender'
              /> */}
            </div>
          </div>
        </div>
      )}

      {/* <DateRangePicker
            startDate={selectedDate.start} // momentPropTypes.momentObj or null,
            startDateId={"your_unique_start_date_id" } // PropTypes.string.isRequired,
            endDate={selectedDate.end} // momentPropTypes.momentObj or null,
            endDateId={"your_unique_end_date_id"}  // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => setSelectedDate({ startDate, endDate })} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => setSelectedDate({ focusedInput })} // PropTypes.func.isRequired,
        /> */}
    </div>
  );
};

export default DatePicker;

export const CustomDate = ({ dateData }) => {
  const checkForDate = (elem) => {
    const timestamp = Date.parse(elem);
    if (isNaN(timestamp) !== false) {
      return elem;
    }
    const nowDate = new Date(elem);
    const date =
      nowDate.getFullYear() +
      '/' +
      ('0' + (nowDate.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + nowDate.getDate()).slice(-2);
    return date;
  };
  if (dateData.period === defaultDateKey.LASTXDAYS) {
    return <>: {dateData?.xDay}</>;
  }
  if (!dateData?.startDate) {
    return <></>;
  }
  return (
    <>
      :&nbsp; &nbsp;
      {`${checkForDate(dateData?.startDate || 0)} to ${checkForDate(dateData?.endDate || 0)}`}
    </>
  );
};
const getDates = (action, xDay) => {
  if (action === 'THISWEEK') {
    const startDate = startOfWeek(new Date());
    // const endDate = endOfWeek(new Date());
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'YESTERDAY') {
    const startDate = addDays(new Date(), -1);
    const endDate = addDays(new Date(), -1);
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'TODAY') {
    const startDate = new Date();
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }

  if (action === 'LAST7DAYS') {
    const startDate = addDays(new Date(), -7);
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'THISMONTH') {
    const startDate = startOfMonth(new Date());
    // const endDate = endOfMonth(new Date());
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'LAST30DAYS') {
    const startDate = addDays(new Date(), -30);
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'THISQUARTER') {
    const startDate = startOfQuarter(new Date());
    // const endDate = endOfQuarter(new Date());
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'THISYEAR') {
    const startDate = startOfYear(new Date());
    // const endDate = endOfYear(new Date());
    const endDate = new Date();
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === 'LAST30DAYS') {
    const startDate = new Date();
    const endDate = addDays(new Date(), -30);
    return { isChange: true, data: { startDate, endDate } };
  }
  if (action === defaultDateKey.LASTXDAYS) {
    if (!isNaN(Number(xDay))) {
      const startDate = addDays(new Date(), -xDay);
      const endDate = new Date();
      return { isChange: true, data: { startDate, endDate } };
    }
  }
  return { isChange: false };
};
